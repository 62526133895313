import CommercialIcon from '../../../assets/icon_commercial.svg';
import ResidentialIcon from '../../../assets/icon_residential.svg';
import messages from './messages';

export const getDescriptionItemValues = (isResidential: boolean) => {
  if (isResidential) {
    return {
      description: messages.CreateSiteModalSiteTypeResidentialInfoBoxText,
      icon: ResidentialIcon,
      title: messages.CreateSiteModalSiteTypeResidentialInfoBoxTitle,
    };
  } else {
    return {
      description: messages.CreateSiteModalSiteTypeCommercialInfoBoxText,
      icon: CommercialIcon,
      title: messages.CreateSiteModalSiteTypeCommercialInfoBoxTitle,
    };
  }
};
