import React from 'react';
import { FormattedMessage } from 'react-intl';
import OutlinedTextField from '../../../components/HookFormFields/TextField';
import messages from './messages';
import SecurityCodeField from './SecurityCodeField';

export interface ISecurityCodeControl {
  disabled: boolean;
  helperText: string;
}

export function SecurityCodeControl({ disabled, helperText }: ISecurityCodeControl) {
  return (
    <OutlinedTextField
      disabled={disabled}
      helperText={helperText}
      InputProps={{ inputComponent: SecurityCodeField }}
      label={<FormattedMessage {...messages.securityCodeControlInputLabel} />}
      name="securityCode"
      required={!disabled}
      fullWidth
      hasTopAndBottomMargin
    />
  );
}
