import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SiteBasicInfo } from '../../../../apiRtk/be4feApi';
import featureFlags from '../../../../config/featureFlags';
import { useUserContext } from '../../../../context/useUserContext';
import { useGetActualSiteId } from '../../../../hooks/siteHooks';
import { useModal } from '../../../../hooks/useModal';
import siteActions from '../../../../modules/sites';
import CreateSiteDialogDeprecated from '../../../CreateSiteModal';
import { useHasFeature } from '../../../Feature/Feature';
import CreateSiteDialog from '../../../SiteModal';
import { SiteButton } from './SiteButton';
import { SiteSwitcher } from './SiteSwitcher';

export interface ISiteMenuProps {
  sitesList?: SiteBasicInfo[];
  isSideBar?: boolean;
}

export function SiteMenu({ isSideBar, sitesList }: ISiteMenuProps) {
  const dispatch = useDispatch();
  const { redirectUserToSite } = useUserContext();
  const theme = useTheme();
  const siteId = useGetActualSiteId();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [editVisible, setEditVisible] = useState(false);
  const isPaymentTypeSelectorFF = useHasFeature(featureFlags.PAYMENT_TYPE_SELECTOR);

  const currentSite = sitesList?.find((item) => item.id === siteId);

  const { Modal: CreateSiteModalDeprecated, onOpen: openCreateSiteModalDeprecated } = useModal({
    Modal: CreateSiteDialogDeprecated,
  });

  const { Modal: CreateSiteModal, onOpen: openCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });

  function openSiteMenu(newPlacement: PopperPlacementType, event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  }

  const addSite = () => {
    setOpen(false);
    if (isPaymentTypeSelectorFF) {
      openCreateSiteModal();
    } else {
      openCreateSiteModalDeprecated();
    }
  };

  const selectSite = (currentSiteId: number) => {
    setOpen(false);
    redirectUserToSite(currentSiteId);
  };

  if (sitesList?.length === 0) {
    return <>{false}</>;
  }

  return (
    <div id="site-menu">
      <Grid
        alignItems="center"
        container
        onMouseLeave={() => setEditVisible(false)}
        onMouseOver={() => setEditVisible(true)}
      >
        <Grid item>
          <SiteButton
            active={siteId !== undefined}
            onClickButton={(event: React.MouseEvent<HTMLButtonElement>) => openSiteMenu('bottom-start', event)}
            open={open}
            siteName={currentSite?.name}
          />
        </Grid>
        {currentSite && editVisible && (
          <Grid item>
            <IconButton onClick={() => dispatch(siteActions.editSiteShowModal(currentSite))}>
              <Box color="common.white">
                <EditIcon />
              </Box>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Popper open={open} style={{ zIndex: theme.zIndex.drawer }} transition anchorEl={anchorEl} placement={placement}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper elevation={24} id="site-menu-list-grow" sx={{ pt: 1 }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <SiteSwitcher
                    currentId={siteId}
                    data={sitesList ?? []}
                    isSideBar={isSideBar}
                    onAdd={addSite}
                    onSelect={selectSite}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <CreateSiteModal />
      <CreateSiteModalDeprecated />
    </div>
  );
}
