import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SITE_CATEGORY, SITE_TYPE } from '../../config/sites';
import { getSiteCategoryMessage, getSiteTypeMessage } from '../../helpers/sites/siteTypeHelper';
import EllipsisText from '../EllipsisText';
import messages from './messages';

interface ISiteType {
  category: SITE_CATEGORY;
  type: SITE_TYPE;
}

export function SiteType({ category, type }: ISiteType) {
  const { formatMessage } = useIntl();
  const color = useMemo(() => (category === SITE_CATEGORY.DEPRECATED ? 'error.dark' : 'inherit'), [category]);
  const categoryLabelText = getSiteCategoryMessage(category);
  const siteTypeLabelText = getSiteTypeMessage(type);

  return (
    <EllipsisText>
      <Tooltip
        title={
          category === SITE_CATEGORY.DEPRECATED ? (
            <FormattedMessage {...messages.sitesDataGridColumnsSiteTypeTooltipDeprecated} />
          ) : undefined
        }
      >
        {categoryLabelText !== undefined && siteTypeLabelText !== undefined ? (
          <Box color={color} component="span">
            {`${formatMessage(categoryLabelText)} / ${formatMessage(siteTypeLabelText)}`}
          </Box>
        ) : (
          <></>
        )}
      </Tooltip>
    </EllipsisText>
  );
}
