import { defineMessages } from 'react-intl';

export default defineMessages({
  finishedStepFinished: {
    defaultMessage: 'Finished',
    description: 'Device Modal - finished',
    id: 'finishedStep.finished',
  },
  noVerifiedMessageMessage: {
    defaultMessage: 'The device could not be verified.',
    description: 'No verified Message - message',
    id: 'verifyDeviceModall.noVerifiedMessage.message',
  },
  successMessageMessage: {
    defaultMessage: 'Your device was added successfully.',
    description: 'Success Message - message',
    id: 'verifyDeviceModall.successMessage.message',
  },
  waitingMessageMessage: {
    defaultMessage: 'Device could not be verified.',
    description: 'Waiting Message - message',
    id: 'verifyDeviceModall.waitingMessage.message',
  },
});
