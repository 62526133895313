import { Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from 'react';
import { FormattedMessage } from 'react-intl/lib';
import RadioGroup from '../../../HookFormFields/RadioGroup';
import { useGetGlobalHooks } from '../../hooks/UseGetGlobalHooks';
import messages from '../../messages';

export interface ISiteTypesListProps {
  isResidential: boolean;
}

export function SiteTypesList({ isResidential }: ISiteTypesListProps) {
  const { upMd } = useGetGlobalHooks();

  return (
    <Stack flexWrap="wrap" sx={{ width: '100%' }}>
      {isResidential && (
        <RadioGroup name="siteType" row={upMd ?? false}>
          <FormControlLabel
            value="MDU"
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeApartmentBuilding} />}
          />
          <FormControlLabel
            value="FAMILY_HOME"
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeFamilyHome} />}
          />
        </RadioGroup>
      )}
      {!isResidential && (
        <RadioGroup name="siteType" row={upMd ?? false}>
          <FormControlLabel
            value="OFFICE"
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeOffice} />}
          />
          <FormControlLabel
            value="MIXED_USE"
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeMixedUse} />}
          />
          <FormControlLabel
            value="OTHER"
            control={<Radio />}
            label={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercialSubtypeOther} />}
          />
        </RadioGroup>
      )}
    </Stack>
  );
}
