import { createContext } from 'react';
import { LicenceModelBillingType, SiteCategory, SiteFeature } from '../apiRtk/be4feApi';

export interface ISiteContextData {
  siteFeatures?: SiteFeature[];
  siteCategory: SiteCategory;
  siteName: string;
  billingType?: LicenceModelBillingType;
}

export const SiteContext = createContext<ISiteContextData | undefined>(undefined);
