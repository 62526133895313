import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS, MOBILE_VIDEO_STATUS } from '../../config/consts';
import { useSiteContext } from '../../context/useSiteContext';
import { getCountMobileVideoFreeDevices } from '../../helpers/dashboard';
import DeviceForFreeTooltip from '../DeviceForFreeTooltip';
import messages from './messages';
import { styles } from './styles';

export function FreeDevices({
  apartments,
  devices,
  status,
}: {
  apartments: { [key: string]: number };
  devices: Array<{ type: string; count: number }>;
  status: string;
}) {
  const { count } = getCountMobileVideoFreeDevices(devices);
  const countApartment = apartments?.[APARTMENT_STATUS.ACTIVE_FOR_FREE] || 0;
  const hasApartmentBillingType = useSiteContext().hasSitePaymentModel('PER_APARTMENT');

  const hasFreeDevices =
    (count > 0 || countApartment > 0) &&
    [MOBILE_VIDEO_STATUS.STANDARD, MOBILE_VIDEO_STATUS.TRIAL_ACTIVE, MOBILE_VIDEO_STATUS.TEMPORARY].includes(status);

  return hasFreeDevices ? (
    <Box sx={styles.text}>
      <DeviceForFreeTooltip isTooltip status={status}>
        <Typography variant="body2">
          <FormattedMessage
            {...(hasApartmentBillingType
              ? messages.creditPoolCardFreeDevicesApartments
              : messages.creditPoolCardFreeDevices)}
            values={{
              count,
              countApartment,
              span: (chunks: React.ReactNode) => (
                <Box component="span" sx={styles.number}>
                  {chunks}
                </Box>
              ),
              text: (chunks: React.ReactNode) => (
                <Box component="span" sx={styles.textColor}>
                  {chunks}
                </Box>
              ),
            }}
          />
        </Typography>
      </DeviceForFreeTooltip>
    </Box>
  ) : (
    <>{false}</>
  );
}
