import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { styles as buttonStyles } from '../../styles/commonButton';

const styles = {
  buttonLabel: {
    color: 'appBar.text',
  },
  mainButton: {
    color: 'common.white',
    height: '100%',
    maxWidth: { md: 320, xs: 232 },
    pl: { sm: 2, xs: 0 },
    pr: { sm: 3, xs: 0 },
    py: 2,
    textTransform: 'none',
  },
  wrapperActive: {
    backgroundColor: {
      md: 'primary.main',
    },
  },
};

export interface ISiteButtonProps {
  active: boolean;
  onClickButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  siteName?: string;
}

export function SiteButton({ active, onClickButton, open, siteName }: ISiteButtonProps) {
  return (
    <Box
      sx={{
        ...buttonStyles.wrapper,
        ...(active && styles.wrapperActive),
      }}
    >
      <Button
        aria-controls="site-menu-list"
        aria-haspopup="true"
        disableRipple
        onClick={onClickButton}
        sx={styles.mainButton}
        startIcon={<LocationOnIcon />}
      >
        <Box sx={buttonStyles.mainButtonHeading}>
          {siteName !== undefined ? (
            <>
              <Box sx={{ ...styles.buttonLabel, display: { lg: 'block', xs: 'none' } }}>
                <Typography>
                  <FormattedMessage {...messages.siteButtonSite} />
                </Typography>
              </Box>
              <Typography
                sx={{
                  ...buttonStyles.mainName,
                  ...buttonStyles.commonColor,
                  ...(!active && buttonStyles.inactiveOpacity),
                }}
              >
                {siteName}
              </Typography>
            </>
          ) : (
            <Typography>
              <FormattedMessage {...messages.siteButtonEmptySite} />
            </Typography>
          )}
        </Box>
        {siteName && (
          <Box sx={{ ...buttonStyles.commonColor, ...buttonStyles.inactiveOpacity }}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        )}
      </Button>
    </Box>
  );
}
