import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PhonebookMembersDataGrid from '../../components/PhonebookMembersDataGrid';
import Title from '../../components/Title';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/phonebooks';
import PhonebookFormModal from '../CallSettings/Phonebooks/PhonebookFormModal';
import messages from './messages';

const PhonebookDetail = ({ data, loading, onEditShowDialog, onLoadDetailPhonebook }) => {
  const { companyId, phonebookId, siteId } = useUrlParams();

  useEffect(() => {
    onLoadDetailPhonebook(companyId, siteId, phonebookId);
  }, [companyId, siteId, phonebookId]);

  return (
    <>
      <Title
        heading={data.name}
        rightSideActions={
          !data.isDefault && (
            <Button color="primary" onClick={() => onEditShowDialog(data)} startIcon={<EditIcon />} variant="contained">
              <FormattedMessage {...messages.phonebookDetailEdit} />
            </Button>
          )
        }
      />
      <PhonebookMembersDataGrid loading={loading} members={data.members} />
      <PhonebookFormModal />
    </>
  );
};

PhonebookDetail.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    isDefault: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
        sipNumber: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    name: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  onEditShowDialog: PropTypes.func.isRequired,
  onLoadDetailPhonebook: PropTypes.func.isRequired,
};

PhonebookDetail.defaultProps = {
  loading: true,
};

const mapStateToProps = (state) => ({
  data: selectors.getPhonebooksDetailData(state),
  loading: selectors.getPhonebooksDetailIsLoading(state),
});

const mapDispatchToProps = {
  onEditShowDialog: actions.editPhonebookShowModal,
  onLoadDetailPhonebook: actions.detailPhonebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhonebookDetail);
