import TableCell from '@mui/material/TableCell';
import MaterialTableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../DataGrid/messages';

const TableFooter = ({
  colSpan,
  onRowsPerPageChange,
  onSelectPage,
  page,
  resultsFiltered,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const selectPage = Math.max(1, page);
  const pages = Array.from({ length: Math.max(Math.ceil(resultsFiltered / rowsPerPage), 1) }, (_, i) => i + 1);

  useEffect(() => {
    if (selectPage > 1 && pages && !pages.includes(selectPage)) {
      onSelectPage(0);
    }
  }, [selectPage, pages]);

  if (!pages.includes(selectPage)) {
    return null;
  }

  if (resultsFiltered <= 0) {
    return null;
  }

  return (
    <MaterialTableFooter>
      <TableRow>
        <TableCell align="right" colSpan={colSpan} size="small" variant="footer">
          <TablePagination
            component="div"
            count={resultsFiltered}
            labelRowsPerPage={<FormattedMessage {...messages.DataGridDesktopPaginationLabel} />}
            onPageChange={(_, value) => onSelectPage(value + 1)}
            onRowsPerPageChange={({ target }) => onRowsPerPageChange(target.value)}
            page={selectPage - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        </TableCell>
      </TableRow>
    </MaterialTableFooter>
  );
};

TableFooter.propTypes = {
  colSpan: PropTypes.number,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  page: PropTypes.number,
  resultsFiltered: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

TableFooter.defaultProps = {
  colSpan: 0,
  page: 0,
  resultsFiltered: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 15],
};

export default TableFooter;
