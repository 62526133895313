export enum SITE_TYPE {
  FAMILY_HOME = 'FAMILY_HOME',
  MDU = 'MDU',
  MIXED_USE = 'MIXED_USE',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
  SINGLE_VILLA = 'SINGLE_VILLA',
  UNKNOWN = 'UNKNOWN',
}

export enum SITE_CATEGORY {
  COMMERCIAL = 'COMMERCIAL',
  DEPRECATED = 'DEPRECATED',
  RESIDENTIAL = 'RESIDENTIAL',
}
