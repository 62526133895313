import { MessageDescriptor } from 'react-intl';
import { SITE_CATEGORY, SITE_TYPE } from '../../config/sites';
import messages from './messages';

export function getSiteCategoryMessage(siteCategory: SITE_CATEGORY): MessageDescriptor | undefined {
  return siteCategoryMap[siteCategory];
}

export function getSiteTypeMessage(siteType: SITE_TYPE): MessageDescriptor | undefined {
  return siteTypeMap[siteType];
}

const siteCategoryMap = {
  [SITE_CATEGORY.COMMERCIAL]: messages.siteCategoryCommercial,
  [SITE_CATEGORY.DEPRECATED]: messages.siteCategoryDeprecated,
  [SITE_CATEGORY.RESIDENTIAL]: messages.siteCategoryResidential,
};

const siteTypeMap = {
  [SITE_TYPE.FAMILY_HOME]: messages.siteTypeNameFamilyHome,
  [SITE_TYPE.MDU]: messages.siteTypeNameApartmentBuilding,
  [SITE_TYPE.MIXED_USE]: messages.siteTypeNameMixedUse,
  [SITE_TYPE.OFFICE]: messages.siteTypeNameOffice,
  [SITE_TYPE.OTHER]: messages.siteTypeNameOther,
  [SITE_TYPE.SINGLE_VILLA]: messages.siteTypeNameFamilyHome,
  [SITE_TYPE.UNKNOWN]: messages.siteTypeNameUnknown,
};
