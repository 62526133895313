import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SiteCategory, SiteType, useCreateSiteMutation } from '../../../apiRtk/be4feApi';
import { LANGUAGES } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { useGetGlobalInfo } from '../../../hooks/useGetGlobalInfo';
import { getSiteValidationSchema } from './validationSchema';

export interface ISiteModalValues {
  lang?: string;
  name: string;
  siteCategory?: SiteCategory;
  siteType?: SiteType;
}

export function useGetGlobalHooks() {
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { companyId } = useUrlParams();
  const [createSite] = useCreateSiteMutation();
  const { downSm, upMd } = useBreakpoints();

  const formMethods = useForm<ISiteModalValues>({
    defaultValues: {
      lang: LANGUAGES.english.locale,
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver<ISiteModalValues>(getSiteValidationSchema(companyId, formatMessage)),
  });

  return {
    companyId,
    createSite,
    dispatch,
    downSm,
    formatMessage,
    formMethods,
    upMd,
  };
}
