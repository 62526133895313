import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateSiteModalNewCompanyAlert: {
    defaultMessage: 'Company created successfully! Create your first site to get started.',
    id: 'createSite.modal.newCompanyAlert',
  },
  CreateSiteModalNewRegistrationAlert: {
    defaultMessage: 'Welcome to My2N! Create your first site to get started.',
    id: 'createSite.modal.newRegistrationAlert',
  },
  CreateSiteModalSiteLanguageHelperText: {
    defaultMessage:
      'Site language sets the user interface and emails. Users can change their language later.' +
      'Translation accuracy may vary for non-English languages due to our use of automated translations',
    id: 'createSite.modal.siteLanguage.helperText',
  },
  CreateSiteModalSiteNameHelperText: {
    defaultMessage: 'Use a descriptive name',
    id: 'createSite.modal.siteName.helperText',
  },
  CreateSiteModalSiteNameLabel: {
    defaultMessage: 'Site name',
    id: 'createSite.modal.siteName.label',
  },
  CreateSiteModalText1: {
    defaultMessage:
      'A separate site helps manage individual buildings, while billing information remains shared at the company level.',
    id: 'createSite.modal.text1',
  },
});
