import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { SiteCategory, SiteType } from '../../../apiRtk/be4feApi';
import { getCreateSiteValidationSchema } from '../../../validations/createSiteValidationSchema';

export function getSiteValidationSchema(companyId?: number, formatMessage?: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    ...getCreateSiteValidationSchema(companyId, formatMessage),
    lang: yup.string(),
    siteCategory: yup.mixed<SiteCategory>(),
    siteType: yup.mixed<SiteType>().required(),
  });
}
