import Box from '@mui/material/Box';
import React from 'react';
import { VERIFICATION_STATUS } from '../../../config/devices';
import NoVerifiedMessage from './NoVerifiedMessage';
import SuccessMessage from './SuccessMessage';
import WaitingMessage from './WaitingMessage';

interface IFinishedStepProps {
  device: {
    deviceType: string;
    verificationStatus: string;
  };
}

const FinishedStep = ({ device }: IFinishedStepProps) => {
  let result = <SuccessMessage />;
  if (device.verificationStatus === VERIFICATION_STATUS.PENDING) {
    result = <WaitingMessage />;
  } else if ([VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(device.verificationStatus)) {
    result = <NoVerifiedMessage />;
  }

  return <Box textAlign="center">{result}</Box>;
};

export default FinishedStep;
