import MuiRadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IRadioGroup {
  name: string;
  children: React.JSX.Element[];
  row?: boolean;
}

function RadioGroup({ children, name, ...other }: IRadioGroup) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MuiRadioGroup {...field} {...other}>
          {children}
        </MuiRadioGroup>
      )}
    />
  );
}

export default RadioGroup;
