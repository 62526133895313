import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateSiteModalBack: {
    defaultMessage: 'Back',
    id: 'createSite.modal.back',
  },
  CreateSiteModalCancel: {
    defaultMessage: 'Cancel',
    id: 'createSite.modal.cancel',
  },
  CreateSiteModalError: {
    defaultMessage: 'There was an error, please try again',
    id: 'createSite.modal.error',
  },
  CreateSiteModalNext: {
    defaultMessage: 'Next',
    id: 'createSite.modal.next',
  },
  CreateSiteModalSiteTypeCommercialSubtypeMixedUse: {
    defaultMessage: 'Mixed-use',
    id: 'createSite.modal.siteType.commercial.subtype.mixedUse',
  },
  CreateSiteModalSiteTypeCommercialSubtypeOffice: {
    defaultMessage: 'Office',
    id: 'createSite.modal.siteType.commercial.subtype.office',
  },
  CreateSiteModalSiteTypeCommercialSubtypeOther: {
    defaultMessage: 'Other',
    id: 'createSite.modal.siteType.commercial.subtype.other',
  },
  CreateSiteModalSiteTypeResidentialSubtypeApartmentBuilding: {
    defaultMessage: 'Apartment building',
    id: 'createSite.modal.siteType.residential.subtype.apartmentBuilding',
  },
  CreateSiteModalSiteTypeResidentialSubtypeFamilyHome: {
    defaultMessage: 'Single-family home',
    id: 'createSite.modal.siteType.residential.subtype.familyHome',
  },
  CreateSiteModalSubmit: {
    defaultMessage: 'Create site',
    id: 'createSite.modal.submit',
  },
  CreateSiteModalSubmitMobile: {
    defaultMessage: 'Create',
    id: 'createSite.modal.submitMobile',
  },
  CreateSiteModalSuccess: {
    defaultMessage: 'Site created successfully',
    id: 'createSite.modal.success',
  },
  CreateSiteModalTitle: {
    defaultMessage: 'Create new site',
    id: 'createSite.modal.title',
  },
});
