import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import featureFlags from '../../../../config/featureFlags';
import { useModal } from '../../../../hooks/useModal';
import CreateCompanyDialog from '../../../CreateCompanyModal';
import CreateSiteDialogDeprecated from '../../../CreateSiteModal';
import { useHasFeature } from '../../../Feature/Feature';
import { SidebarMobile } from '../../../Sidebar/SidebarMobile';
import CreateSiteDialog from '../../../SiteModal';

interface ISidebarMobileButton {
  isVersionWarningDisplayed: boolean;
}

function SidebarMobileButton({ isVersionWarningDisplayed }: ISidebarMobileButton) {
  const [open, setOpen] = useState(false);
  const isPaymentTypeSelectorFF = useHasFeature(featureFlags.PAYMENT_TYPE_SELECTOR);

  const { Modal: CreateSiteModalDeprecated, onOpen: openCreateSiteModalDeprecated } = useModal({
    Modal: CreateSiteDialogDeprecated,
  });
  const { Modal: CreateSiteModal, onOpen: openCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });
  const { Modal: CreateCompanyModal, onOpen: onOpenCreateCompanyModal } = useModal({
    Modal: CreateCompanyDialog,
  });

  return (
    <Box sx={{ display: { md: 'none', xs: 'flex' } }}>
      <Box sx={{ color: 'common.white', pl: 1 }}>
        <IconButton aria-label="menu" onClick={() => setOpen((prevState) => !prevState)}>
          {open ? (
            <ArrowBackIcon sx={{ color: 'common.white', opacity: 1 }} />
          ) : (
            <MenuIcon sx={{ color: 'primary.contrastText', opacity: '0.45' }} />
          )}
        </IconButton>
        {open && (
          <SidebarMobile
            isVersionWarningDisplayed={isVersionWarningDisplayed}
            onClose={() => setOpen((prevState) => !prevState)}
            onOpenCreateSiteModal={isPaymentTypeSelectorFF ? openCreateSiteModal : openCreateSiteModalDeprecated}
            onOpenCreateCompanyModal={onOpenCreateCompanyModal}
          />
        )}
      </Box>
      <CreateSiteModalDeprecated />
      <CreateSiteModal />
      <CreateCompanyModal />
    </Box>
  );
}

export default SidebarMobileButton;
