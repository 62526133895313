import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { DataGridActionButton, DataGridSearchField, SitesDataGrid, Title } from '../../components';
import CreateSiteDialogDeprecated from '../../components/CreateSiteModal';
import { useHasFeature } from '../../components/Feature/Feature';
import CreateSiteDialog from '../../components/SiteModal';
import featureFlags from '../../config/featureFlags';
import { changeFilter, dataGridProps, getDataGridSelector, useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useModal } from '../../hooks/useModal';
import commonActions from '../../modules/common';
import actions, { NAME as SITES_MODULE } from '../../modules/sites';
import messages from './messages';

const Sites = ({ onDeleteSite, onLoadSites, onSearch, sitesTable }) => {
  const companyId = useGetActualCompanyId();
  const { downSm } = useBreakpoints();
  const [fulltext, setFulltext] = useState(sitesTable.filter.fulltext || '');
  const isPaymentTypeSelectorFF = useHasFeature(featureFlags.PAYMENT_TYPE_SELECTOR);
  const { Modal: CreateSiteModalDeprecated, onOpen: openCreateSiteModalDeprecated } = useModal({
    Modal: CreateSiteDialogDeprecated,
  });
  const { Modal: CreateSiteModal, onOpen: openCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getSitesList, {
      ...sitesTable,
      companyId,
    })(name, value);
  };

  const onDelete = (event, site) => {
    event.preventDefault();
    onDeleteSite(site);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.sitesTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
        rightSideActions={
          <DataGridActionButton
            onClick={isPaymentTypeSelectorFF ? openCreateSiteModal : openCreateSiteModalDeprecated}
            startIcon={<AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(messages.sitesToolsAddNewSite, messages.sitesToolsAddNewSiteMobile)}
          </DataGridActionButton>
        }
      />
      <SitesDataGrid
        {...sitesTable}
        companyId={companyId}
        onAddSite={isPaymentTypeSelectorFF ? openCreateSiteModal : openCreateSiteModalDeprecated}
        onChangeFilter={onChangeFilter}
        onDeleteSite={onDelete}
        onLoadSites={onLoadSites}
      />
      <CreateSiteModalDeprecated />
      <CreateSiteModal />
    </>
  );
};

Sites.propTypes = {
  onDeleteSite: PropTypes.func.isRequired,
  onLoadSites: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  sitesTable: dataGridProps.isRequired,
};

const mapStateToProps = (state) => ({
  sitesTable: getDataGridSelector(state, SITES_MODULE, 'list'),
});

const mapDispatchToProps = {
  onDeleteSite: actions.deleteSiteShowModal,
  onLoadSites: actions.getSitesList,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
