import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import apartmentActions, { selectors as apartmentSelectors } from '../../modules/apartments';
import AccessGroupAutocomplete from '../AccessGroupAutocomplete';
import useAccessGroupFormValidationSchema from '../AccessGroupAutocomplete/useAccessGroupFormValidationSchema';
import PrimaryModal from '../PrimaryModal';
import messages from './messages';

// eslint-disable-next-line max-statements
function ApartmentAccessGroupsModal() {
  const { companyId, siteId } = useUrlParams();
  const hasFormItemKey = 'hasForm';
  const newItemId = 'newItemId';
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const data = {
    apartmentId: useSelector(apartmentSelectors.getApartmentAccessGroupsModalApartmentId),
    groups: useSelector(selectors.getAccessGroupsListAll),
    isLoading: useSelector(selectors.isCreateAddApartmentAccessGroupsFetching),
    isLoadingApartmentGroups: useSelector(selectors.getAccessGroupApartmentGroupsFetching),
    open: useSelector(apartmentSelectors.getApartmentAccessGroupsModalShow),
    selectedGroups: useSelector(selectors.getApartmentAccessGroupsList),
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(useAccessGroupFormValidationSchema()),
    shouldUnregister: true,
  });
  const { isDirty, isValid } = methods.formState;

  useEffect(() => {
    methods.reset({
      group: data.selectedGroups,
      [hasFormItemKey]: false,
      name: '',
    });
  }, [data.selectedGroups]);

  useEffect(() => {
    if (data.open) {
      dispatch(actions.getAccessGroupsListAll());
      dispatch(actions.getApartmentAccessGroupsList(data.apartmentId));
    }
  }, [data.open]);

  const handleAddGroups = (form) => {
    const newGroupName = form?.name;
    const currentGroups = [];
    form.group.forEach((item) => {
      if (item.id !== newItemId) {
        currentGroups.push(item.id);
      }
    });
    dispatch(actions.addApartmentAccessGroupsWithCreate(data.apartmentId, newGroupName, currentGroups));
    dispatch(apartmentActions.getSiteApartmentsList(companyId, siteId));
    onClose();
  };

  function onClose() {
    dispatch(apartmentActions.setApartmentAccessGroupHideModal());
  }

  return (
    !data.isLoadingApartmentGroups && (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleAddGroups)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.setAccessGroupsModalAddButtonLabel)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={data.isLoading}
            name="apartment-access-groups-dialog"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={data.open || data.isLoading}
            title={formatMessage(messages.setAccessGroupsModalTitle)}
          >
            <ModalText />
            <AccessGroupAutocomplete
              hasFormItemKey={hasFormItemKey}
              newItemId={newItemId}
              optionsData={[...data.groups]}
            />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
}

function ModalText() {
  return (
    <Typography component="span" variant="body1">
      <FormattedMessage {...messages.setAccessGroupsModalText} values={{ p: (chunks) => <p>{chunks}</p> }} />
    </Typography>
  );
}

export default ApartmentAccessGroupsModal;
