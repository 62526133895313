import { defineMessages } from 'react-intl';

export default defineMessages({
  sitesDataGridColumnsPaymentModelPerApartment: {
    defaultMessage: 'Per apartment',
    id: 'sitesDataGrid.columns.paymentModel.perApartment',
  },
  sitesDataGridColumnsPaymentModelPerDevice: {
    defaultMessage: 'Per device',
    id: 'sitesDataGrid.columns.paymentModel.perDevice',
  },
});
