import { defineMessages } from 'react-intl';

export default defineMessages({
  siteCategoryCommercial: {
    defaultMessage: 'Commercial',
    id: 'siteCategory.commercial',
  },
  siteCategoryDeprecated: {
    defaultMessage: 'Deprecated',
    id: 'siteCategory.deprecated',
  },
  siteCategoryResidential: {
    defaultMessage: 'Residential',
    id: 'siteCategory.residential',
  },
  sitesDataGridColumnsSiteTypeTooltipDeprecated: {
    defaultMessage: 'New site types are available. Open the site detail to choose the one that best fits your project.',
    id: 'sitesDataGrid.columns.siteType.tooltipDeprecated',
  },
  siteTypeNameApartmentBuilding: {
    defaultMessage: 'Apartment building',
    id: 'siteTypeName.apartmentBuilding',
  },
  siteTypeNameFamilyHome: {
    defaultMessage: 'Family home',
    id: 'siteTypeName.familyHome',
  },
  siteTypeNameMixedUse: {
    defaultMessage: 'Mixed-use',
    id: 'siteTypeName.mixedUse',
  },
  siteTypeNameOffice: {
    defaultMessage: 'Office',
    id: 'siteTypeName.office',
  },
  siteTypeNameOther: {
    defaultMessage: 'Other',
    id: 'siteTypeName.other',
  },
  siteTypeNameUnknown: {
    defaultMessage: 'Unknown',
    id: 'siteTypeName.unknown',
  },
});
