import { createSelector } from 'reselect';
import { ROLES } from '../../config/consts';

export const getDataCompanySwitcher = (state) => state.common.companySwitcher.data;
export const getDataSiteSwitcher = (state) => state.common.siteSwitcher.data;
export const getGTCAgreements = (state) => state.common.gtc.agreements.data;
export const getGTCDetail = (state) => state.common.gtc.detail.data;
export const getGTCList = (state) => state.common.gtc.list.data;
export const getGlobalFeatures = (state) => state.common.featureFlags.globalFeatures;
export const getIsSidebarCollapsed = (state) => state.common.sidebar.collapse;
export const isCompanyAdmin = (state) =>
  !!state.common.companySwitcher.data.find((item) => item?.roles.indexOf(ROLES.COMPANY_ADMIN) >= 0);
export const isDidInvalidCompanySwitcher = (state) => state.common.companySwitcher.didInvalid;
export const isDidInvalidGTCAgreements = (state) => state.common.gtc.agreements.didInvalid;
export const isDidInvalidGTCDetail = (state) => state.common.gtc.detail.didInvalid;
export const isDidInvalidGTCList = (state) => state.common.gtc.list.didInvalid;
export const isDidInvalidSiteSwitcher = (state) => state.common.siteSwitcher.didInvalid;
export const isFetchingCompanySwitcher = (state) => state.common.companySwitcher.isFetching;
export const isFetchingFeatures = (state) => state.common.featureFlags.isFetching;
export const isFetchingGTCAgreements = (state) => state.common.gtc.agreements.isFetching;
export const isFetchingGTCDetail = (state) => state.common.gtc.detail.isFetching;
export const isFetchingGTCList = (state) => state.common.gtc.list.isFetching;
export const isNotFoundGTCDetail = (state) => state.common.gtc.detail.notFound;
export const isServiceAlertOpen = createSelector(
  ({ common }) => common.serviceAlert.show,
  (show) => show
);
export const getServiceAlertHeight = createSelector(
  ({ common }) => common.serviceAlert.height,
  (height) => height
);
export const isSiteAdmin = (state) =>
  !!state.common.siteSwitcher.data.find((item) => item?.roles.indexOf(ROLES.ADMIN) >= 0);
export const getGtcOptOutLabel = (state) => state.common.gtc.optOutText.label;
export const getGtcOptOutHelperText = (state) => state.common.gtc.optOutText.helperText;
export const isCompanyAboutModalOpen = (state) => state.common.showCompanyAboutModal;
export const isSiteVersionWarningDisplayed = (state) => state.common.showVersionWarning;
export const didInvalidSiteSwitcher = (state) => state.common.siteSwitcher.didInvalid;
export const didInvalidCompanySwitcher = (state) => state.common.companySwitcher.didInvalid;
