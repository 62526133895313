import { defineMessages } from 'react-intl';

export default defineMessages({
  sitesDataGridActionsDelete: {
    defaultMessage: 'Delete site',
    description: 'Sites dataGrid - actions - delete',
    id: 'sitesDataGrid.actions.delete',
  },
  sitesDataGridActionsRename: {
    defaultMessage: 'Rename site',
    description: 'Sites dataGrid - actions - rename',
    id: 'sitesDataGrid.actions.rename',
  },
  sitesDataGridActionsSetSiteType: {
    defaultMessage: 'Set site type',
    description: 'Sites dataGrid - actions - set site type',
    id: 'sitesDataGrid.actions.setSiteType',
  },
  sitesDataGridColumnsMobileVideoStatusDesktop: {
    defaultMessage: 'Mobile Video status',
    description: 'Sites dataGrid - mobile video status column for desktop',
    id: 'sitesDataGrid.columns.mobileVideoStatusDesktop',
  },
  sitesDataGridColumnsMobileVideoStatusMobile: {
    defaultMessage: 'MV status',
    description: 'Sites dataGrid - mobile video status column for mobile devices',
    id: 'sitesDataGrid.columns.mobileVideoStatusMobile',
  },
  sitesDataGridColumnsMy2nId: {
    defaultMessage: 'My2N ID',
    description: 'Sites dataGrid - my2n Id column',
    id: 'sitesDataGrid.columns.my2nId',
  },
  sitesDataGridColumnsName: {
    defaultMessage: 'Site name',
    description: 'Sites dataGrid - name column',
    id: 'sitesDataGrid.columns.name',
  },
  sitesDataGridColumnsPaymentModel: {
    defaultMessage: 'Payment model',
    id: 'sitesDataGrid.columns.paymentModel',
  },
  sitesDataGridColumnsType: {
    defaultMessage: 'Site type',
    description: 'Sites dataGrid - site type column',
    id: 'sitesDataGrid.columns.type',
  },
  sitesDataGridNoRowsOverlayButton: {
    defaultMessage: 'Create new site',
    description: 'Sites dataGrid - no rows overlay - button create new site',
    id: 'sitesDataGrid.noRowsOverlay.button',
  },
  sitesDataGridNoRowsOverlayHeaderText: {
    defaultMessage: 'No sites',
    description: 'Sites dataGrid - no rows overlay - header text',
    id: 'sitesDataGrid.noRowsOverlay.headerText',
  },
  sitesDataGridNoRowsOverlayText: {
    defaultMessage: 'Once you add a new site, it will appear here',
    description: 'Sites dataGrid - no rows overlay - text',
    id: 'sitesDataGrid.noRowsOverlay.text',
  },
  sitesDataGridNoRowsSearchOverlayClearSearch: {
    defaultMessage: 'Clear search',
    description: 'Sites dataGrid - no rows search overlay - btn clear search',
    id: 'sitesDataGrid.noRowsSearchOverlay.clearSearch',
  },
  sitesDataGridNoRowsSearchOverlayHeaderText: {
    defaultMessage: 'No search results',
    description: 'Sites dataGrid - no rows search overlay - header text',
    id: 'sitesDataGrid.noRowsSearchOverlay.headerText',
  },
  sitesDataGridNoRowsSearchOverlayText: {
    defaultMessage: 'We couldn\'t find a match for "{searchText}". Please try another search.',
    description: 'Sites dataGrid - no rows search overlay - text',
    id: 'sitesDataGrid.noRowsSearchOverlay.text',
  },
});
