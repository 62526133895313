import React from 'react';
import { SideBarMenuButton } from './SideBarMenuButton';

export interface ISideBarMenuProps {
  buttonEmptyLabel: JSX.Element;
  buttonItemLabel: JSX.Element;
  buttonStartIcon: JSX.Element;
  buttonValue: string;
  children: JSX.Element;
  onButtonClick: () => void;
  open: boolean;
}

export function SideBarMenu({
  buttonEmptyLabel,
  buttonItemLabel,
  buttonStartIcon,
  buttonValue,
  children,
  onButtonClick,
  open,
}: ISideBarMenuProps) {
  return (
    <>
      <SideBarMenuButton
        emptyLabel={buttonEmptyLabel}
        itemLabel={buttonItemLabel}
        onClick={onButtonClick}
        open={open}
        startIcon={buttonStartIcon}
        value={buttonValue}
      />
      {open && children}
    </>
  );
}
