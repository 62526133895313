import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { ServiceDto } from '../../apiRtk/accessControlApi';
import { DEVICE_SERIAL_CODE_MASK } from '../../config/devices';
import messages from './messages';

export interface IFormProps {
  id: string;
  serialNumber: string;
  deviceType: string;
  services: ServiceDto[];
  verificationStatus: string;
  name: string;
}

export const getDefaultValues = (device: IFormProps) => ({
  deviceType: device.deviceType,
  isIDT: false,
  name: device.name,
  securityCode: '',
  serialNumber: device.serialNumber,
  storeHash: false,
});

export const getValidationSchema = (formatMessage: (message: MessageDescriptor) => string) =>
  yup.object().shape({
    deviceType: yup.string(),
    isIDT: yup.boolean(),
    name: yup
      .string()
      .required(formatMessage(messages.verifyDeviceModalRequired))
      .max(50, formatMessage(messages.verifyDeviceModalTooLong)),
    securityCode: yup.string().when('isIDT', {
      is: false,
      then: (schema) =>
        schema
          .required(formatMessage(messages.verifyDeviceModalRequired))
          .test('isValidSecurityCode', formatMessage(messages.verifyDeviceModalSecurityCodeNoValid), (value) =>
            new RegExp(/^([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})$/i).test(value)
          ),
    }),
    serialNumber: yup
      .string()
      .required(formatMessage(messages.verifyDeviceModalRequired))
      .test('isValidSerialNumber', formatMessage(messages.verifyDeviceModalSerialNumberNoValid), (value) =>
        new RegExp(DEVICE_SERIAL_CODE_MASK).test(value)
      ),
    storeHash: yup.boolean(),
  });
