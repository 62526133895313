import Stack from '@mui/material/Stack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CommercialIcon from '../../../assets/icon_commercial.svg';
import ResidentialIcon from '../../../assets/icon_residential.svg';
import { LearnAboutSiteTypesText } from '../components/LearnAboutSiteTypesText';
import { SiteCategoryItem } from './components/SiteCategoryItem';
import messages from './messages';

export function SelectSiteTypeStep() {
  return (
    <Stack spacing={2} sx={{ mb: 4 }}>
      <LearnAboutSiteTypesText />
      <Stack
        alignItems="center"
        direction={{
          md: 'row',
          xs: 'column',
        }}
        spacing={3}
        sx={{ maxWidth: '100%' }}
      >
        <SiteCategoryItem
          value="RESIDENTIAL"
          icon={ResidentialIcon}
          description={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidential} />}
        />
        <SiteCategoryItem
          value="COMMERCIAL"
          icon={CommercialIcon}
          description={<FormattedMessage {...messages.CreateSiteModalSiteTypeCommercial} />}
        />
      </Stack>
    </Stack>
  );
}
