import { createContext } from 'react';
import { Roles } from '../modules/commonTypes';
import { IUserBase } from '../modules/user/store';

export interface IUserContextData extends IUserBase {
  companies: IUserCompany[];
}

export interface IUserCompany {
  id: number;
  name: string;
  sites: IUserSite[];
  roles: Roles[];
}

export interface IUserSite {
  id: number;
  name: string;
  roles: Roles[];
  apartments: IUserApartment[];
}

export interface IUserApartment {
  id: number;
  name: string;
  roles: Roles[];
}

export const UserContext = createContext<IUserContextData | undefined>(undefined);
