import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactSVG } from 'react-svg';
import { SiteCategory } from '../../../../apiRtk/be4feApi';
import { ISiteModalValues } from '../../hooks/UseGetGlobalHooks';

const styles = {
  active: {
    '&:hover': {
      cursor: 'default',
    },
    backgroundColor: 'background.default',
    borderColor: 'primary.main',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    '&:hover': {
      backgroundColor: 'background.default',
      borderColor: 'primary.main',
      cursor: 'pointer',
    },
    border: 1,
    borderColor: 'secondary.light',
    borderRadius: '4px',
    color: 'primary.main',
    display: 'block',
    padding: { md: 4, xs: 2 },
    width: '100%',
  },
  text: {
    fontWeight: 'bold',
  },
};

export interface ISiteCategoryItemProps {
  icon: string;
  description: React.JSX.Element;
  value: SiteCategory;
}

export function SiteCategoryItem({ description, icon, value }: ISiteCategoryItemProps) {
  const { setValue, trigger, watch } = useFormContext<ISiteModalValues>();
  const siteCategory = watch('siteCategory');
  const checked = siteCategory === value;

  useEffect(() => {
    setValue('siteType', undefined);
    trigger();
  }, []);

  const onClickHandler = () => {
    if (!checked) {
      setValue('siteCategory', value);
    }
  };

  return (
    <Stack
      onClick={onClickHandler}
      sx={{
        ...styles.root,
        ...(checked && styles.active),
      }}
    >
      <Stack data-testid="site-category-item" spacing={1} sx={styles.content} direction={{ md: 'column', xs: 'row' }}>
        <ReactSVG src={icon} />
        <Typography sx={styles.text}>{description}</Typography>
      </Stack>
    </Stack>
  );
}
