import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RLink } from 'react-router-dom';
import { MOBILE_VIDEO_STATUS, PATHS } from '../../config/consts';
import { SERVICE } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import CustomDate from '../CustomDate';
import SuccessIconMessage from '../SuccessIconMessage';
import WarningIconMessage from '../WarningIconMessage';
import messages from './messages';

// eslint-disable-next-line max-statements
const ResultMessage = ({ company, isSuccess, onCloseModal, site }) => {
  const licenceModel = site.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel;
  const licenceStatus = licenceModel?.status;

  let description = null;
  let nextPayment = null;
  if (isSuccess && site.type === SITE_TYPE.MDU) {
    description = <FormattedMessage {...messages.resultMessageMduMessage} />;
    if (licenceStatus === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE) {
      nextPayment = <FormattedMessage {...messages.resultMessageMduNextPaymentFreeTrial} />;
    } else if (licenceModel?.nextPayment) {
      nextPayment = (
        <FormattedMessage
          values={{
            paymentDate: <CustomDate dateValue={licenceModel?.nextPayment} />,
          }}
          {...messages.resultMessageMduNextPayment}
        />
      );
    }
  } else if (!isSuccess) {
    description = (
      <FormattedMessage
        values={{
          url: (chunks) => (
            <Link
              onClick={() => onCloseModal()}
              component={RLink}
              to={PATHS.DEVICES_OVERVIEW.replace(':companyId', company.id).replace(':siteId', site.id)}
            >
              {chunks}
            </Link>
          ),
        }}
        {...messages.resultMessageMduWarningText}
      />
    );
  }

  return (
    <>
      {isSuccess && (
        <SuccessIconMessage>
          <FormattedMessage {...messages.resultMessageMduSuccess} />
        </SuccessIconMessage>
      )}
      {!isSuccess && (
        <WarningIconMessage>
          <FormattedMessage {...messages.resultMessageMduWarning} />
        </WarningIconMessage>
      )}
      {description && (
        <>
          <Divider />
          <Box pt={2}>
            <Typography paragraph variant="caption">
              {description}
              {nextPayment}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

ResultMessage.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  isSuccess: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        licenceModel: PropTypes.shape({
          nextPayment: PropTypes.string,
          status: PropTypes.string,
        }),
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
};

export default ResultMessage;
