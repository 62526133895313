import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateSiteModalSiteTypeCommercial: {
    defaultMessage: 'Commercial',
    id: 'createSite.modal.siteType.commercial',
  },
  CreateSiteModalSiteTypeResidential: {
    defaultMessage: 'Residential',
    id: 'createSite.modal.siteType.residential',
  },
  CreateSiteModalText2: {
    defaultMessage: 'Select the site type that best fits your project. <link>Learn more</link>',
    id: 'createSite.modal.text2',
  },
});
