import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingType as BillingTypes } from '../../modules/commonTypes';
import EllipsisText from '../EllipsisText';
import messages from './messages';

export function BillingType({ billingType }: { billingType: BillingTypes }) {
  return (
    <EllipsisText>
      <FormattedMessage
        {...(billingType === BillingTypes.PER_APARTMENT
          ? messages.sitesDataGridColumnsPaymentModelPerApartment
          : messages.sitesDataGridColumnsPaymentModelPerDevice)}
      />
    </EllipsisText>
  );
}
