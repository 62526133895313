import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedTextField } from '../../HookFormFields/TextField';
import SiteLanguageSelect from '../../SiteLanguageSelect';
import { useGetGlobalHooks } from '../hooks/UseGetGlobalHooks';
import messages from './messages';

export interface ICreateSiteStepProps {
  isFirstLogin?: boolean;
  isNewCompany?: boolean;
}

export function CreateSiteStep({ isFirstLogin, isNewCompany }: ICreateSiteStepProps) {
  const { formatMessage } = useGetGlobalHooks();

  return (
    <Stack spacing={3}>
      {isNewCompany && (
        <Alert icon={<CheckCircleIcon />} severity="success" variant="outlined">
          <FormattedMessage {...messages.CreateSiteModalNewCompanyAlert} />
        </Alert>
      )}
      {isFirstLogin && !isNewCompany && (
        <Alert icon={<CheckCircleIcon />} severity="success" variant="outlined">
          <FormattedMessage {...messages.CreateSiteModalNewRegistrationAlert} />
        </Alert>
      )}
      <Typography>
        <FormattedMessage {...messages.CreateSiteModalText1} />
      </Typography>
      <Stack spacing={3} mt={3} mb={3}>
        <OutlinedTextField
          fullWidth
          helperText={formatMessage(messages.CreateSiteModalSiteNameHelperText)}
          label={formatMessage(messages.CreateSiteModalSiteNameLabel)}
          id="name"
          name="name"
          data-testid="site-name"
          required
        />
        <SiteLanguageSelect
          data-testid="site-language"
          helperText={formatMessage(messages.CreateSiteModalSiteLanguageHelperText)}
          required
        />
      </Stack>
    </Stack>
  );
}
