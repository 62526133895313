import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateSiteModalSiteTypeCommercialInfoBoxText: {
    defaultMessage: '<ul><li>Payments per device</li><li>Ideal for office or mixed-use buildings</li></ul>',
    id: 'createSite.modal.siteType.commercial.infoBox.text',
  },
  CreateSiteModalSiteTypeCommercialInfoBoxTitle: {
    defaultMessage: 'Commercial',
    id: 'createSite.modal.siteType.commercial.infoBox.title',
  },
  CreateSiteModalSiteTypeResidentialInfoBoxText: {
    defaultMessage:
      '<ul><li>Payments per apartment</li><li>Includes the Apartment admin feature</li><li>Offers a free license for one apartment when a 2N intercom is used</li></ul>',
    id: 'createSite.modal.siteType.residential.infoBox.text',
  },
  CreateSiteModalSiteTypeResidentialInfoBoxTitle: {
    defaultMessage: 'Residential',
    id: 'createSite.modal.siteType.residential.infoBox.title',
  },
  CreateSiteModalSiteTypeResidentialSubtypeInfoTooltip: {
    defaultMessage: 'This selection won’t affect your site’s features  but helps us better understand our users.',
    id: 'createSite.modal.siteType.residential.subtype.infoTooltip',
  },
  CreateSiteModalSiteTypeResidentialSubtypeSelect: {
    defaultMessage: 'Which option better describes your project?',
    id: 'createSite.modal.siteType.residential.subtype.select',
  },
  CreateSiteModalText2: {
    defaultMessage: 'Select the site type that best fits your project. <link>Learn more</link>',
    id: 'createSite.modal.text2',
  },
});
