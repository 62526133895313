import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '../../../components/LoadingButton';
import { DEVICE_TYPE } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { useSiteContext } from '../../../context/useSiteContext';
import apartmentActions, { selectors as apartmentsSelectors } from '../../../modules/apartments';
import { selectors as deviceSelectors } from '../../../modules/devices';
import actions from '../../../modules/devices';
import { handleSaveMultipleDevicesCallSettings } from '../../../modules/devices/helpers/saveDeviceHelper';
import { saveMultipleDevicesCallSettingsRequest } from '../../../modules/devices/saveActions';
import actionsSite, { selectors as sitesSelectors } from '../../../modules/sites';
import { selectors as userSelectors } from '../../../modules/user';
import { addThirdPartyDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import CallSettingsStep from '../CallSettingsStep';
import { ICallSettingsStepData } from '../CallSettingsStep/CallSettingsStep';
import messagesCallSettings from '../CallSettingsStep/messages';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep/FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import { getValidationParameters } from '../helpers';
import messagesStepOne from './OneStep/messages';
import { OneStep } from './OneStep/OneStep';
import { getThirdPartyValidationSchema, IFormData } from './validationSchema';

export interface IThirdPartyForm {
  onClearModal: () => void;
  onClose: () => void;
  onSelectDevice: (device: any) => void;
  open: boolean;
}

export function ThirdPartyForm({ onClearModal, onClose, onSelectDevice, open }: IThirdPartyForm) {
  const currentUser = useSelector(userSelectors.getCurrentUserSelector);
  const finishedData = useSelector(deviceSelectors.getFinishedDataDeviceModal);
  const floors = useSelector(apartmentsSelectors.getFloors);
  const isAdvanceFinished = useSelector(deviceSelectors.isAdvanceFinishedAddDeviceModal);
  const isFinished = useSelector(deviceSelectors.isFinishedAddDeviceModal);
  const siteType = useSelector(sitesSelectors.getCurrentSiteType);
  const validationExistName = useSelector(getValidationParameters);
  const isApartmentMandatory = useSiteContext().isSiteFeatureEnabled('NON_INTERCOM_DEVICE_FORBIDDEN_OUTSIDE_APARTMENT');

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callSettings, setCallSettings] = useState<ICallSettingsStepData | undefined>(undefined);
  const { companyId, siteId } = validationExistName;

  useEffect(() => {
    dispatch(apartmentActions.getApartmentsList(companyId, siteId));
    dispatch(apartmentActions.getSiteFloorsList(companyId, siteId));
    dispatch(actionsSite.getSitesUsersNames());
  }, []);

  useEffect(() => {
    if (!isFinished) {
      setStep(1);
    } else if (isFinished && step < 2) {
      dispatch(actionsSite.getSitesUsersNames());
      setStep(2);
    } else if (isFinished && isAdvanceFinished && step < 3) {
      setStep(3);
    }
  }, [isFinished, isAdvanceFinished, step]);

  const formData = useForm<IFormData>({
    defaultValues: {
      existUser: false,
      hasForm: false,
      hasSelectedApartment: false,
      isApartmentRequired: isApartmentMandatory,
      isSiteMDU: siteType === SITE_TYPE.MDU,
      name: '',
      user: currentUser?.id,
      userId: currentUser.id,
    },
    mode: 'onChange',
    resolver: yupResolver(
      getThirdPartyValidationSchema(formatMessage, companyId, siteId)
    ) as unknown as Resolver<IFormData>,
  });

  useEffect(() => {
    if (formData.formState.isSubmitting) {
      setIsSubmitting(true);
    }
  }, [formData.formState.isSubmitting]);

  function onCreateNewDevice() {
    setStep(1);
    formData.reset();
    onSelectDevice(null);
    onClearModal();
    dispatch(actions.addDeviceClearModalData());
    setIsSubmitting(false);
  }

  function getActions() {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => onCreateNewDevice()} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepOne.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepOne.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!formData.formState.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={isSubmitting}
            onClick={formData.handleSubmit(handleSubmit)}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messagesStepOne.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesCallSettings.formActionBackButton} />
            </Button>
          </Box>
          <Button onClick={() => setStep(4)}>
            <FormattedMessage {...messagesCallSettings.formActionFinishLaterButton} />
          </Button>
          <Button
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => {
              if (callSettings?.state.devicesCallSettings.some((device) => device.selected)) {
                handleSaveMultipleDevicesCallSettings(callSettings, (values) =>
                  dispatch(saveMultipleDevicesCallSettingsRequest(values))
                );
              } else {
                setStep(3);
              }
            }}
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messagesCallSettings.formActionFinishedButton} />
          </Button>
        </>
      );
    }

    if (step === 3) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesFinished.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={() => onCreateNewDevice()}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="button" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  }

  function handleSubmit(values: IFormData) {
    dispatch(addThirdPartyDevice(values, floors));
  }

  return (
    <FormProvider {...formData}>
      <form autoComplete="off" noValidate onSubmit={formData.handleSubmit(handleSubmit)}>
        <AddDeviceModal
          actions={getActions()}
          title={<DeviceTitle device={DEVICE_TYPE.THIRD_PARTY} />}
          open={open}
          onClose={onClose}
        >
          {step === 1 && <OneStep floors={floors} siteType={siteType} />}
          {step === 2 && (
            <CallSettingsStep
              addedDevice={finishedData || {}}
              companyId={companyId}
              handleChange={setCallSettings}
              siteId={siteId}
            />
          )}
          {step >= 3 && (
            <FinishedStep
              callSettingsSkipped={step === 4}
              device={finishedData}
              deviceModalType={DEVICE_TYPE.THIRD_PARTY}
            />
          )}
        </AddDeviceModal>
      </form>
    </FormProvider>
  );
}
