import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

enum SITE_TYPE_deprecated {
  MDU = 'MDU',
  OTHER = 'OTHER',
  SINGLE_VILLA = 'SINGLE_VILLA',
  UNKNOWN = 'UNKNOWN',
}

const SiteTypeDeprecated = ({ type }: { type: SITE_TYPE_deprecated }) => {
  const color = useMemo(() => (type === SITE_TYPE_deprecated.UNKNOWN ? 'error.dark' : 'inherit'), [type]);

  return (
    <Box
      color={color}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <FormattedMessage {...siteTypeMapDeprecated[type]} />
    </Box>
  );
};

export default SiteTypeDeprecated;

const siteTypeMapDeprecated = {
  [SITE_TYPE_deprecated.MDU]: messages.siteTypeMdu,
  [SITE_TYPE_deprecated.OTHER]: messages.siteTypeOther,
  [SITE_TYPE_deprecated.SINGLE_VILLA]: messages.siteTypeSingleVilla,
  [SITE_TYPE_deprecated.UNKNOWN]: messages.siteTypeUnknown,
};
