import { NAME as PHONEBOOKS_MODULE } from '.';
import { getDataGridSelector } from '../../helpers/dataGrid';

export const getPhonebooksDetailIsLoading = (state) => state.phonebooks.detail.isFetching;

export const getPhonebooksDetailData = (state) => state.phonebooks.detail.data;

export const getPhonebooksList = (state) => state.phonebooks.list.data;

export const deletePhonebookShow = (state) => state.phonebooks.deleteModal?.show || false;
export const deletePhonebook = (state) => state.phonebooks.deleteModal?.phonebook;
export const deletePhoneBookFetching = (state) => state.phonebooks.deleteModal?.isFetching || false;

export const isPhonebookModalOpen = (state) => state.phonebooks.phonebookModal?.open;
export const isPhonebookModalEdit = (state) => state.phonebooks.phonebookModal?.isEdit;
export const getPhonebookModalData = (state) =>
  state.phonebooks.phonebookModal?.data || {
    externalId: null,
    id: null,
    members: [],
    name: '',
  };
export const getPhonebookMembersTable = (state) => getDataGridSelector(state, PHONEBOOKS_MODULE, 'membersList');
