import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconDoneSvg from '../../../assets/icon_done.svg';
import messages from './messages';

const SuccessMessage = () => (
  <>
    <img alt="" src={iconDoneSvg} />
    <Typography color="success.main" paragraph>
      <FormattedMessage {...messages.successMessageMessage} />
    </Typography>
  </>
);

export default SuccessMessage;
