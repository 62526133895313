import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SelectSiteTypeModal, UserModal } from '../../components';
import AvailableCreditDialog from '../../components/AvailableCreditModal';
import { APARTMENT_STATUS, CURRENCY, MOBILE_VIDEO_STATUS, MOBILE_VIDEO_TYPE } from '../../config/consts';
import { DEVICE_STATUS, DEVICE_TYPE } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import DeviceModal from '../../containers/DeviceModal';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import { useModal } from '../../hooks/useModal';
import { selectors as companySelectors } from '../../modules/company';
import deviceActions, { selectors as deviceSelectors } from '../../modules/devices';
import siteActions, { selectors as siteSelectors } from '../../modules/sites';
import subscriptionActions from '../../modules/subscription';
import { CONTEXT, selectors as userSelectors } from '../../modules/user';
import AlertBox from './AlertBox';
import AlertBoxFreeDevices from './AlertBoxFreeDevices';
import Cards from './Cards';
import LinksSection from './LinksSection';
import WarningSelectSiteBox from './WarningSelectSiteBox';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const SiteDashboard = ({
  apartments,
  currency,
  currentUserId,
  devices,
  isDashboardInvalid,
  isDashboardLoading,
  licenceModel,
  mvService,
  notVerifiedDevices,
  onActivateService,
  onGetNotVerifiedDevices,
  onLoadDashboard,
  site,
}) => {
  const query = useQuery();
  const companyId = useGetActualCompanyId();
  const redirectData = useLocation().state;
  const siteId = useGetActualSiteId();
  const [addUserModal, toggleUserModal] = useState(false);
  const hasFromParams = query.has('selectTypeSite');
  const dispatch = useDispatch();
  const { Modal: SelectSiteType, onOpen: openSelectSiteTypeModal } = useModal({
    Modal: SelectSiteTypeModal,
    onClose: () => {
      dispatch(siteActions.setSiteTypeModalClearData());
    },
  });
  const { Modal: DecoratedDeviceModal, onOpen: onOpenAddDevice } = useModal({
    Modal: DeviceModal,
  });
  const { Modal: AvailableCreditModal, onOpen: onOpenAvailableCreditModal } = useModal({
    Modal: AvailableCreditDialog,
  });

  useEffect(() => {
    onLoadDashboard(companyId, siteId);
  }, [companyId, siteId, isDashboardInvalid]);

  useEffect(() => {
    if ((hasFromParams || redirectData?.showSelectSiteTypeModal) && site.type === SITE_TYPE.UNKNOWN) {
      openSelectSiteTypeModal();
    }
  }, [hasFromParams, redirectData]);

  return (
    <div>
      {!isDashboardLoading && (
        <>
          {site?.type === SITE_TYPE.UNKNOWN && (
            <WarningSelectSiteBox onOpenDialog={openSelectSiteTypeModal} site={site} />
          )}
          <AlertBox
            companyId={companyId}
            licenceModel={licenceModel}
            notVerifiedDevices={notVerifiedDevices}
            onActivateService={onActivateService}
            onGetNotVerifiedDevices={onGetNotVerifiedDevices}
            onOpenAvailableCreditModal={onOpenAvailableCreditModal}
            siteId={siteId}
            subscribed={mvService?.subscribed || false}
          />
          {licenceModel?.type === MOBILE_VIDEO_TYPE.STANDARD && (
            <AlertBoxFreeDevices currency={currency || CURRENCY.EUR} userId={currentUserId} />
          )}
          <Cards
            siteType={site.type}
            apartments={apartments}
            currency={currency}
            devices={devices}
            isSiteMdu={site?.type === SITE_TYPE.MDU}
            licenceModel={licenceModel}
            mvService={mvService}
            onAddDevice={onOpenAddDevice}
            onOpenAvailableCreditModal={onOpenAvailableCreditModal}
          />
          <LinksSection
            companyId={companyId}
            onAddUser={() => toggleUserModal(true)}
            siteId={siteId}
            licenceModel={licenceModel}
          />
        </>
      )}
      <DecoratedDeviceModal />
      <SelectSiteType company={{ id: companyId }} site={site} />
      <AvailableCreditModal />
      {addUserModal && (
        <UserModal
          context={CONTEXT.SITE_USERS}
          onClose={() => toggleUserModal(false)}
          open={addUserModal}
          showApartmentField
        />
      )}
    </div>
  );
};

const deviceStatus = PropTypes.shape({
  [DEVICE_STATUS.OK]: PropTypes.number,
  [DEVICE_STATUS.WARN]: PropTypes.number,
  [DEVICE_STATUS.ERROR]: PropTypes.number,
});

SiteDashboard.propTypes = {
  apartments: PropTypes.shape({
    [APARTMENT_STATUS.ACTIVE_FOR_FREE]: PropTypes.number,
    [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: PropTypes.number,
    [APARTMENT_STATUS.NO_SERVICE]: PropTypes.number,
    [APARTMENT_STATUS.UNLICENSED]: PropTypes.number,
    [APARTMENT_STATUS.OTHER]: PropTypes.number,
  }),
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
  currentUserId: PropTypes.number.isRequired,
  devices: PropTypes.shape({
    [DEVICE_TYPE.ACCESS_UNIT]: deviceStatus,
    [DEVICE_TYPE.CHAMELEON]: deviceStatus,
    [DEVICE_TYPE.CLIP]: deviceStatus,
    [DEVICE_TYPE.DOOR_STATION]: deviceStatus,
    [DEVICE_TYPE.HIPMO]: deviceStatus,
    [DEVICE_TYPE.IDT]: deviceStatus,
    [DEVICE_TYPE.ID_COMPACT]: deviceStatus,
    [DEVICE_TYPE.ID_TALK]: deviceStatus,
    [DEVICE_TYPE.ID_VIEW]: deviceStatus,
    [DEVICE_TYPE.IP_INTERCOM]: deviceStatus,
    [DEVICE_TYPE.IP_STYLE]: deviceStatus,
    [DEVICE_TYPE.THIRD_PARTY]: deviceStatus,
  }),
  isDashboardInvalid: PropTypes.bool.isRequired,
  isDashboardLoading: PropTypes.bool.isRequired,
  licenceModel: PropTypes.shape({
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    type: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_TYPE)),
    unlicensedCount: PropTypes.number,
  }).isRequired,
  mvService: PropTypes.shape({ subscribed: PropTypes.bool }).isRequired,
  notVerifiedDevices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onActivateService: PropTypes.func.isRequired,
  onGetNotVerifiedDevices: PropTypes.func.isRequired,
  onLoadDashboard: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

SiteDashboard.defaultProps = {
  apartments: {},
  currency: null,
  devices: {},
};

const mapStateToProps = (state) => ({
  apartments: siteSelectors.getDashboardServicesApartments(state),
  currency: companySelectors.getCurrentCompanyCurrencySelector(state),
  currentUserId: userSelectors.getCurrentUserIdSelector(state),
  devices: siteSelectors.getDashboardDevices(state),
  isDashboardInvalid: siteSelectors.getDashboardIsInvalid(state),
  isDashboardLoading: siteSelectors.getDashboardIsFetching(state),
  licenceModel: siteSelectors.getDashboardMvServiceLicenceModel(state),
  mvService: siteSelectors.getDashboardMvService(state),
  notVerifiedDevices: deviceSelectors.getNotVerifiedDevices(state),
  site: siteSelectors.getSiteData(state),
});

const mapDispatchToProps = {
  onActivateService: subscriptionActions.activateMobileVideoLicence,
  onGetNotVerifiedDevices: deviceActions.getNotVerifiedDevicesRequest,
  onLoadDashboard: siteActions.getSiteDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDashboard);
