import types from './types';

export const loadDataCompanySwitcher = () => ({ type: types.COMPANY_SWITCHER_LOAD_DATA_REQUEST });
export const loadGTCAgreements = (companyId) => ({
  companyId,
  type: types.LOAD_GTC_AGREEMENTS_REQUEST,
});
export const loadGTCDetail = (fullPath) => ({ fullPath, type: types.LOAD_GTC_DETAIL_REQUEST });
export const loadGTCList = (companyId = null) => ({ companyId, type: types.LOAD_GTC_LIST_REQUEST });
export const loadGlobalFeatures = () => ({ type: types.LOAD_GLOBAL_FEATURES_REQUEST });
export const sendSupport = (values) => ({
  type: types.SEND_SUPPORT_FORM_REQUEST,
  values,
});
export const setServiceAlertHeight = (height) => ({ height, type: types.SET_SERVICE_ALERT_HEIGHT });
export const setServiceAlertShow = (show) => ({ show, type: types.SET_SERVICE_ALERT_SHOW });
export const toggleCollapseSidebar = () => ({ type: types.COLLAPSE_SIDEBAR });
export const getGtcOptOutText = () => ({ type: types.GET_GTC_OPT_OUT_TEXT_REQUEST });
export const search = (onLoad, params) => ({ onLoad, params, type: types.SEARCH });
export const siteVersionWarningShow = () => ({ type: types.SITE_VERSION_WARNING_SHOW });
