import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import React from 'react';

const styles = {
  label: {
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    px: 0,
    py: 1.5,
    width: '100%',
  },
};

export interface ISideBarMenuButtonProps {
  emptyLabel: JSX.Element;
  itemLabel: JSX.Element;
  onClick: () => void;
  open: boolean;
  startIcon: JSX.Element;
  value: string;
}

export function SideBarMenuButton({ emptyLabel, itemLabel, onClick, open, startIcon, value }: ISideBarMenuButtonProps) {
  return (
    <ButtonBase onClick={onClick} sx={styles.root}>
      <Grid alignItems={value ? 'stretch' : 'center'} container>
        <Grid item>
          <Box color={value ? 'primary.main' : 'secondary.main'} px={2}>
            {startIcon}
          </Box>
        </Grid>
        <Grid item>
          <Box
            color={value ? 'primary.main' : 'secondary.main'}
            fontSize={value ? 16 : 14}
            fontWeight={value ? 'bold' : 'regular'}
            sx={styles.label}
          >
            {value || emptyLabel}
          </Box>
          <Box color="secondary.main" fontSize={12} sx={styles.label} textAlign="left">
            {value ? itemLabel : ''}
          </Box>
        </Grid>
        <Grid item>
          <Box color="secondary.main">{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Box>
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
