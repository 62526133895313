import InfoIcon from '@mui/icons-material/Info';
import { Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SiteCategory } from '../../../api/siteApiTS';
import { LearnAboutSiteTypesText } from '../components/LearnAboutSiteTypesText';
import { ISiteModalValues, useGetGlobalHooks } from '../hooks/UseGetGlobalHooks';
import { SiteCategoryDescriptionItem } from './components/SiteCategoryDescriptionItem';
import { SiteTypesList } from './components/SiteTypesList';
import { getDescriptionItemValues } from './helpers';
import messages from './messages';

export function SelectSubtypeStep() {
  const { getValues } = useFormContext<ISiteModalValues>();
  const siteCategory = getValues('siteCategory');
  const isResidential = siteCategory === SiteCategory.RESIDENTIAL;
  const { description, icon, title } = getDescriptionItemValues(isResidential);
  const { upMd } = useGetGlobalHooks();

  return (
    <Stack spacing={3} sx={{ mb: 4 }}>
      <LearnAboutSiteTypesText />
      <SiteCategoryDescriptionItem
        icon={icon}
        title={<FormattedMessage {...title} />}
        description={<FormattedMessage {...description} />}
      />
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ fontWeight: 'bold' }}>
            <FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeSelect} />
          </Typography>
          <Tooltip
            title={<FormattedMessage {...messages.CreateSiteModalSiteTypeResidentialSubtypeInfoTooltip} />}
            placement={upMd ? 'right-start' : 'bottom-end'}
          >
            <InfoIcon sx={{ color: 'info.dark' }} />
          </Tooltip>
        </Stack>
        <SiteTypesList isResidential={isResidential} />
      </Stack>
    </Stack>
  );
}
