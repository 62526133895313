import { defineMessages } from 'react-intl';

export default defineMessages({
  finishedStepFinished: {
    defaultMessage: 'Finished',
    description: 'Device Modal - finished',
    id: 'finishedStep.finished',
  },
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'x2nDevice.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Form action - cancel button',
    id: 'x2nDevice.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Form action - next button',
    id: 'x2nDevice.formAction.nextBtn',
  },
  verifyDeviceModalRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Verify Device Modal - required value',
    id: 'verifyDeviceModal.required',
  },
  verifyDeviceModalSecurityCodeNoValid: {
    defaultMessage: 'Please enter My2N Security Code in valid format.',
    description: 'Verify Device Modal 2N - security code no valid',
    id: 'verifyDeviceModal.securityCode.noValid',
  },
  verifyDeviceModalSerialNumberNoValid: {
    defaultMessage: 'Please enter serial number in valid format.',
    description: 'Verify Device Modal 2N - serial number no valid',
    id: 'verifyDeviceModal.serialNumber.noValid',
  },
  verifyDeviceModalTitle: {
    defaultMessage: "Verify a 2N Device','verifyDeviceModal.title",
    description: 'Verify a 2N Device',
    id: 'verifyDeviceModal.title',
  },
  verifyDeviceModalTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Verify Device Modal - value is too long',
    id: 'verifyDeviceModal.tooLong',
  },
});
