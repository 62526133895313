import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApartmentsOverviewCard,
  BusinessModelCard,
  CreditPoolCard,
  DevicesAndLicensesCard,
  MobileVideoCard,
  PaymentsAndCreditsConsumption,
} from '../../components';
import { useHasFeature } from '../../components/Feature/Feature';
import { APARTMENT_STATUS, CURRENCY, MOBILE_VIDEO_STATUS } from '../../config/consts';
import { DEVICE_CATEGORY, DEVICE_STATUS, DEVICE_TYPE } from '../../config/devices';
import featureFlags from '../../config/featureFlags';
import { isActivable, isExpirable } from '../../helpers/mobileVideo';
import { useUrlParams } from '../../helpers/urlParams';
import DevicesOverviewCard from './DevicesOverviewCard';
import messages from './messages';
import NoDevicesBox from './NoDevicesBox';

const styles = {
  cardHeight: {
    height: '100%',
  },
  screen: {
    mb: 2,
  },
};

// eslint-disable-next-line complexity
const Cards = ({
  apartments,
  currency,
  devices,
  isSiteMdu,
  licenceModel,
  mvService,
  onAddDevice,
  onOpenAvailableCreditModal,
  siteType,
}) => {
  const { companyId, siteId } = useUrlParams();
  const freeMVFFEnabled = useHasFeature(featureFlags.FREE_MV_FOR_APARTMENT);

  // eslint-disable-next-line max-statements
  const getCount = (deviceType) => {
    let count = 0;
    let fix = 0;
    let warn = 0;
    let types = [];
    if (deviceType === DEVICE_CATEGORY.intercom) {
      types = [
        DEVICE_TYPE.IP_INTERCOM,
        DEVICE_TYPE.IP_INTERCOM_V2,
        DEVICE_TYPE.IP_ONE_INTERCOM,
        DEVICE_TYPE.IP_STYLE,
        DEVICE_TYPE.DOOR_STATION,
      ];
    } else if (deviceType === DEVICE_CATEGORY.answeringUnit) {
      types = [DEVICE_TYPE.CLIP, DEVICE_TYPE.IDT, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.ID_VIEW];
    } else if (deviceType === DEVICE_CATEGORY.smartphoneOrTablet) {
      types = [DEVICE_TYPE.HIPMO];
    } else if (deviceType === DEVICE_CATEGORY.accessUnit) {
      types = [DEVICE_TYPE.ACCESS_UNIT, DEVICE_TYPE.ACCESS_UNIT_M, DEVICE_TYPE.CHAMELEON];
    } else if (deviceType === DEVICE_CATEGORY.thirdParty) {
      types = [DEVICE_TYPE.THIRD_PARTY, DEVICE_TYPE.AXIS_THIRD_PARTY];
    }
    types.forEach((type) => {
      if (devices[type]) {
        Object.keys(devices[type]).forEach((key) => {
          count += devices[type][key];
          if (key === DEVICE_STATUS.ERROR) {
            fix += devices[type][key];
          } else if (key === DEVICE_STATUS.WARN) {
            warn += devices[type][key];
          }
        });
      }
    });
    return { count, fix, warn };
  };
  const getDevicesCount = () => {
    const intercom = getCount(DEVICE_CATEGORY.intercom);
    const answeringUnit = getCount(DEVICE_CATEGORY.answeringUnit);
    const smartphoneOrTablet = getCount(DEVICE_CATEGORY.smartphoneOrTablet);
    const accessUnit = getCount(DEVICE_CATEGORY.accessUnit);
    const thirdParty = getCount(DEVICE_CATEGORY.thirdParty);
    return {
      [DEVICE_CATEGORY.intercom]: {
        count: intercom.count,
        fix: intercom.fix,
        warn: intercom.warn,
      },
      [DEVICE_CATEGORY.answeringUnit]: {
        count: answeringUnit.count,
        fix: answeringUnit.fix,
        warn: answeringUnit.warn,
      },
      [DEVICE_CATEGORY.smartphoneOrTablet]: {
        count: smartphoneOrTablet.count,
        fix: smartphoneOrTablet.fix,
        warn: smartphoneOrTablet.warn,
      },
      [DEVICE_CATEGORY.accessUnit]: {
        count: accessUnit.count,
        fix: accessUnit.fix,
        warn: accessUnit.warn,
      },
      [DEVICE_CATEGORY.thirdParty]: {
        count: thirdParty.count,
        fix: thirdParty.fix,
        warn: thirdParty.warn,
      },
    };
  };
  const getWholeCount = () => {
    const intercom = getCount(DEVICE_CATEGORY.intercom);
    const answeringUnit = getCount(DEVICE_CATEGORY.answeringUnit);
    const smartphoneOrTablet = getCount(DEVICE_CATEGORY.smartphoneOrTablet);
    const accessUnit = getCount(DEVICE_CATEGORY.accessUnit);
    const thirdParty = getCount(DEVICE_CATEGORY.thirdParty);
    return intercom.count + answeringUnit.count + smartphoneOrTablet.count + accessUnit.count + thirdParty.count;
  };

  return (
    <Grid container spacing={2} sx={styles.screen} wrap="wrap">
      <Grid item lg={isSiteMdu ? 4 : 6} sx={styles.cardHeight} xs={12}>
        {Object.keys(devices).length === 0 || getWholeCount() === 0 ? (
          <NoDevicesBox onAddDevice={onAddDevice} />
        ) : (
          <DevicesOverviewCard
            devices={getDevicesCount()}
            onAddDevice={onAddDevice}
            siteMVStatus={licenceModel?.status}
          />
        )}
      </Grid>
      {isSiteMdu && (
        <Grid item lg={4} xs={12}>
          <ApartmentsOverviewCard
            apartments={apartments}
            freeMVFFEnabled={freeMVFFEnabled}
            siteMVType={licenceModel?.type}
            siteMVStatus={licenceModel?.status}
            freeLicence={licenceModel?.freeLicencesWithIntercomCount}
            unusedFreeLicence={licenceModel?.unusedFreeLicencesWithIntercomCount}
            siteType={siteType}
          />
        </Grid>
      )}
      <Grid item lg={isSiteMdu ? 4 : 6} sm={6} xs={12}>
        <MobileVideoCard onOpenAvailableCreditModal={onOpenAvailableCreditModal} small={isSiteMdu} />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        {isActivable(licenceModel?.status) || isExpirable(licenceModel?.status) ? (
          <BusinessModelCard model={<FormattedMessage {...messages.siteDashboardCustom} />} />
        ) : (
          <CreditPoolCard
            freeMVFFEnabled={freeMVFFEnabled}
            siteType={siteType}
            apartments={apartments}
            currency={currency}
            isRecurringPayment={mvService?.subscribed}
            isSiteMdu={isSiteMdu}
            licenceModel={licenceModel}
            mobileVideoDevices={mvService?.devices || []}
          />
        )}
      </Grid>
      <Grid item lg={8} xs={12}>
        {isActivable(licenceModel?.status) || isExpirable(licenceModel?.status) ? (
          <DevicesAndLicensesCard licenseModel={licenceModel} />
        ) : (
          <PaymentsAndCreditsConsumption companyId={companyId} siteId={siteId} />
        )}
      </Grid>
    </Grid>
  );
};

const deviceStatus = PropTypes.shape({
  [DEVICE_STATUS.OK]: PropTypes.number,
  [DEVICE_STATUS.WARN]: PropTypes.number,
  [DEVICE_STATUS.ERROR]: PropTypes.number,
});

Cards.propTypes = {
  apartments: PropTypes.shape({
    [APARTMENT_STATUS.ACTIVE_FOR_FREE]: PropTypes.number,
    [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: PropTypes.number,
    [APARTMENT_STATUS.NO_SERVICE]: PropTypes.number,
    [APARTMENT_STATUS.UNLICENSED]: PropTypes.number,
    [APARTMENT_STATUS.OTHER]: PropTypes.number,
  }),
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
  devices: PropTypes.shape({
    [DEVICE_TYPE.ACCESS_UNIT]: deviceStatus,
    [DEVICE_TYPE.CHAMELEON]: deviceStatus,
    [DEVICE_TYPE.CLIP]: deviceStatus,
    [DEVICE_TYPE.DOOR_STATION]: deviceStatus,
    [DEVICE_TYPE.HIPMO]: deviceStatus,
    [DEVICE_TYPE.IDT]: deviceStatus,
    [DEVICE_TYPE.ID_COMPACT]: deviceStatus,
    [DEVICE_TYPE.ID_TALK]: deviceStatus,
    [DEVICE_TYPE.ID_VIEW]: deviceStatus,
    [DEVICE_TYPE.IP_INTERCOM]: deviceStatus,
    [DEVICE_TYPE.IP_STYLE]: deviceStatus,
    [DEVICE_TYPE.THIRD_PARTY]: deviceStatus,
  }),
  isSiteMdu: PropTypes.bool.isRequired,
  licenceModel: PropTypes.shape({
    billingType: PropTypes.string,
    freeLicencesWithIntercomCount: PropTypes.number,
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    type: PropTypes.string,
    unusedFreeLicencesWithIntercomCount: PropTypes.number,
  }).isRequired,
  mvService: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.shape({})),
    subscribed: PropTypes.bool,
  }).isRequired,
  onAddDevice: PropTypes.func.isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  siteType: PropTypes.string.isRequired,
};

Cards.defaultProps = {
  apartments: {},
  currency: null,
  devices: {},
};

export default Cards;
