import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Device2NIcon } from '../../../components';
import ButtonScanQRCode from '../../../components/ButtonScanQRCode';
import OutlinedTextField from '../../../components/HookFormFields/TextField';
import { DEVICE_SERIAL_CODE_MASK } from '../../../config/devices';
import DeviceDesktopScanQR from '../../DeviceDesktopScanQR';
import DeviceMobileScanQR from '../../DeviceMobileScanQR';
import messages from './messages';
import { SecurityCodeControl } from './SecurityCodeControl';
import { SerialNumberControl } from './SerialNumberControl';
import { StoreHashControl } from './StoreHashControl';

export interface IOneStep {
  isMobileView: boolean;
  my2nId: string;
}

export function OneStep({ isMobileView, my2nId }: IOneStep) {
  const { formatMessage } = useIntl();
  const [showScanCode, setShowScanCode] = useState(isMobileView);
  const { setValue, watch } = useFormContext();
  const serialNumber = watch('serialNumber');
  const deviceType = watch('deviceType');
  const isIDT = watch('isIDT');

  const onCloseScanCode = () => setShowScanCode(false);

  const onToggleScanCode = () => setShowScanCode((prev) => !prev);

  const onScanCodeSuccess = (options: { serialNumber: string; securityCode: string }) => {
    if (options) {
      setValue('serialNumber', options.serialNumber);
      setValue('securityCode', options.securityCode);
      setShowScanCode(false);
    }
  };

  useEffect(() => {
    if (isMobileView !== showScanCode) {
      setShowScanCode(isMobileView);
    }
  }, [isMobileView]);

  if (isMobileView && showScanCode) {
    return <DeviceMobileScanQR onClose={onCloseScanCode} onSuccess={onScanCodeSuccess} />;
  }

  return (
    <>
      <Typography>
        <FormattedMessage {...messages.x2nDeviceOneStepSerialNumberParagraph} />
      </Typography>
      {!showScanCode ? (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs>
            <SerialNumberControl />
            <SecurityCodeControl
              disabled={isIDT}
              helperText={formatMessage(
                isIDT
                  ? messages.x2nDeviceOneStepSecurityCodeThisDeviceNotRequiredHint
                  : messages.x2nDeviceOneStepSecurityCodeSomeDevicesNotRequiredHint
              )}
            />
          </Grid>
          <Grid item sx={{ display: { sm: 'block', xs: 'none' } }} xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Device2NIcon
                deviceType={deviceType}
                noFound={new RegExp(DEVICE_SERIAL_CODE_MASK).test(serialNumber) && deviceType === ''}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <DeviceDesktopScanQR onSuccess={onScanCodeSuccess} />
      )}
      <Box sx={{ mb: 2 }}>
        <ButtonScanQRCode onClick={onToggleScanCode} open={showScanCode} />
      </Box>
      <StoreHashControl />
      <OutlinedTextField
        helperText={<FormattedMessage {...messages.x2nDeviceOneStepNameHelperText} />}
        label={<FormattedMessage {...messages.x2nDeviceOneStepNameLabel} />}
        name="name"
        required
        fullWidth
        hasTopAndBottomMargin
      />
      {isIDT !== true && (
        <Alert severity="info">
          <FormattedMessage
            {...messages.x2nDeviceOneStepSecurityCodeInfoBox}
            values={{
              my2nId,
              url: (chunks) => (
                <Link
                  href="https://www.2n.com/en-GB/faqs/2n-ip-intercom-into-my2n-i-dont-have-a-card-with-the-security-code-kA0Rn000000r5mLKAQ?category=management-platforms&product=my2n-management-platform"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
}
