import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import TableControlPanel from '../../../components/TableControlPanel';
import { changeFilter, dataGridProps, getDataGridSelector } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import commonActions from '../../../modules/common';
import actions, { NAME as PHONEBOOKS_MODULE } from '../../../modules/phonebooks';
import messages from '../messages';
import PhonebookFormModal from './PhonebookFormModal';
import PhoneBookInfo from './PhoneBookInfo';
import PhonebooksDataGrid from './PhonebooksDataGrid';

const Phonebooks = ({ onAddPhonebook, onLoadPhonebooks, onSearch, phonebooksDataGrid }) => {
  const { companyId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();

  const [fulltext, setFulltext] = useState(phonebooksDataGrid.filter.fulltext);
  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.loadPhonebooks, {
      ...phonebooksDataGrid,
      companyId,
      siteId,
    })(name, value);
  };

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Box sx={{ pr: 3, pt: 3 }}>
          <TableControlPanel
            addButtonAction={onAddPhonebook}
            addButtonLabel={<FormattedMessage {...messages.toolsAddNewPhonebook} />}
            onSearchFieldChange={onChangeFilter}
            searchField
            searchFieldPlaceholder={formatMessage(messages.toolsSearchPlaceholder)}
            searchFieldText={fulltext}
          />
        </Box>
        <PhonebooksDataGrid
          {...phonebooksDataGrid}
          companyId={companyId}
          onLoadPhonebooks={onLoadPhonebooks}
          siteId={siteId}
        />
      </Paper>
      <PhoneBookInfo />
      <PhonebookFormModal />
    </>
  );
};

Phonebooks.propTypes = {
  onAddPhonebook: PropTypes.func.isRequired,
  onLoadPhonebooks: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  phonebooksDataGrid: dataGridProps.isRequired,
};

const mapStateToProps = (state) => ({
  phonebooksDataGrid: getDataGridSelector(state, PHONEBOOKS_MODULE, 'list'),
});

export default connect(mapStateToProps, {
  onAddPhonebook: actions.addPhonebookShowModal,
  onLoadPhonebooks: actions.loadPhonebooks,
  onSearch: commonActions.search,
})(Phonebooks);
