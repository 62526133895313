import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ActionModalError } from '../../../components/_DesignSystemWrappers/Modal';
import actions, { selectors } from '../../../modules/phonebooks';
import messages from '../messages';

const styles = {
  firstDescription: {
    mb: 1.5,
  },
  list: {
    mb: 0,
  },
};

function DeletePhonebookModal() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isDeletePhonebookOpen = useSelector(selectors.deletePhonebookShow);
  const phoneBook = useSelector(selectors.deletePhonebook);
  const onClose = () => dispatch(actions.deletePhonebookHideModal());

  const onSubmit = () => {
    dispatch(actions.deletePhonebookConfirm(phoneBook));
  };

  return (
    <ActionModalError
      icon={<DeleteIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={isDeletePhonebookOpen}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.deletePhonebookModalConfirm)}
      secondaryButtonText={formatMessage(messages.deletePhonebookModalCancel)}
      title={formatMessage(messages.deletePhonebookModalTitle)}
    >
      <Typography sx={styles.firstDescription}>
        <FormattedMessage {...messages.deletePhonebookModalDescription1} />
      </Typography>
      <Typography component="div">
        <FormattedMessage
          {...messages.deletePhonebookModalDescription2}
          values={{
            list: (
              <Box component="ul" sx={styles.list}>
                <FormattedMessage tagName="li" {...messages.deletePhonebookModalListRow1} />
                <FormattedMessage tagName="li" {...messages.deletePhonebookModalListRow2} />
              </Box>
            ),
          }}
        />
      </Typography>
    </ActionModalError>
  );
}

export default DeletePhonebookModal;
