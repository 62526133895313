import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconWaitingSvg from '../../../assets/icon_warning.svg';
import messages from './messages';

const WaitingMessage = () => (
  <>
    <img alt="" src={iconWaitingSvg} />
    <Typography paragraph>
      <FormattedMessage {...messages.waitingMessageMessage} />
    </Typography>
  </>
);

export default WaitingMessage;
