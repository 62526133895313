import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MaterialLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IconBillingSvg from '../../assets/ico-billing.svg';
import IconInvoiceSvg from '../../assets/ico-invoice.svg';
import IconUsersSvg from '../../assets/ico-users.svg';
import { RecentSitesDataGrid, Title } from '../../components';
import CreateCompanyDialog from '../../components/CreateCompanyModal';
import CreateSiteDialogDeprecated from '../../components/CreateSiteModal';
import { useHasFeature } from '../../components/Feature/Feature';
import PaymentsTable from '../../components/PaymentsTable';
import CreateSiteDialog from '../../components/SiteModal';
import { PATHS } from '../../config/consts';
import featureFlags from '../../config/featureFlags';
import { PAYMENT_CONTEXT } from '../../config/payments';
import SiteAboutDialog from '../../containers/Sites/SiteAboutModal';
import { dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useLocationState } from '../../hooks/useLocationState';
import { useModal } from '../../hooks/useModal';
import { selectors as authSelectors } from '../../modules/auth';
import { getCompanyData } from '../../modules/company/selectors';
import actionsPayments, { NAME as PAYMENTS_MODULE } from '../../modules/payments';
import { selectors as siteSelectors } from '../../modules/sites';
import actionsSite, { NAME as SITES_MODULE } from '../../modules/sites';
import DashboardLink from './DashboardLink';
import messages from './messages';
import SiteOverview from './SiteOverview';

const CompanyDashboard = ({ company, isFirstLogin, onLoadPayments, onLoadSites, paymentsTable, sitesTable }) => {
  const companyId = useGetActualCompanyId();
  const isPaymentTypeSelectorFF = useHasFeature(featureFlags.PAYMENT_TYPE_SELECTOR);
  const { clearLocationState, getLocationState } = useLocationState();
  const isCompanyFetching = useSelector(siteSelectors.getRecentSiteIsFetching);
  const isNewCompany = getLocationState()?.isNewCompany;
  const companyHasNoSite = sitesTable.data.length === 0;

  useEffect(() => {
    if ((isNewCompany || isFirstLogin) && companyHasNoSite && !isCompanyFetching) {
      openCreateSiteModal();
      clearLocationState();
    }
  }, [isNewCompany, isFirstLogin, companyHasNoSite, isCompanyFetching]);

  const { Modal: CreateSiteModalDeprecated, onOpen: openCreateSiteModalDeprecated } = useModal({
    Modal: CreateSiteDialogDeprecated,
  });
  const { Modal: CreateSiteModal, onOpen: openCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });
  const { Modal: SiteAboutModal, onOpen: onOpenSiteAboutModal } = useModal({
    Modal: SiteAboutDialog,
  });
  const { Modal: CreateCompanyModal, onOpen: onOpenCreateCompanyModal } = useModal({
    Modal: CreateCompanyDialog,
  });

  if (companyId === undefined) {
    return <></>;
  }

  return (
    <>
      <Title heading={<FormattedMessage {...messages.companyDashboardTitle} />} />
      <Grid container spacing={4}>
        <Grid container item spacing={4}>
          <Grid item lg={3} md={4} xs={12}>
            <SiteOverview
              companyId={company.id}
              onAddSite={isPaymentTypeSelectorFF ? openCreateSiteModal : openCreateSiteModalDeprecated}
              onSiteAboutModalShow={onOpenSiteAboutModal}
              siteCount={sitesTable?.resultsTotal}
            />
          </Grid>
          <Grid item lg={9} md={8} xs={12}>
            <Paper elevation={1} sx={{ borderRadius: 0, height: '100%' }}>
              <RecentSitesDataGrid
                companyId={companyId}
                data={sitesTable.data.slice(0, 5)}
                didInvalid={sitesTable.didInvalid}
                isFetching={sitesTable.isFetching}
                onLoadSites={onLoadSites}
                order={sitesTable.order}
                resultsTotal={sitesTable.resultsTotal}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <PaymentsTable
              {...paymentsTable}
              action={(payment) => (
                <div>
                  {['INVOICE_ISSUED', 'BEFORE_COMPLETION', 'COMPLETED'].includes(payment.purchaseState) && (
                    <MaterialLink component="a" href={payment.link} rel="noreferrer noopener" target="_blank">
                      <Button color="primary" startIcon={<GetAppIcon />} variant="outlined">
                        <FormattedMessage {...messages.recentPaymentsInvoice} />
                      </Button>
                    </MaterialLink>
                  )}
                </div>
              )}
              companyId={companyId}
              context={PAYMENT_CONTEXT.RECENT_PAYMENTS}
              key={company?.id}
              onLoadPayments={onLoadPayments}
              text={
                <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>
                  <FormattedMessage {...messages.recentPaymentsViewAllPayments} />
                </Link>
              }
            />
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item md={4} xs={12}>
            <DashboardLink
              icon={IconUsersSvg}
              id="admins-dashboardlink"
              title={<FormattedMessage {...messages.companyDashboardLinksCompanyAdminsTitle} />}
              to={PATHS.COMPANY_ADMIN.replace(':companyId', companyId)}
            >
              <FormattedMessage {...messages.companyDashboardLinksCompanyAdminsDescription} />
            </DashboardLink>
          </Grid>
          <Grid item md={4} xs={12}>
            <DashboardLink
              icon={IconBillingSvg}
              id="billinginfo-dashboardlink"
              title={<FormattedMessage {...messages.companyDashboardLinksBillingInfoTitle} />}
              to={PATHS.BILLING_INFO.replace(':companyId', companyId)}
            >
              <FormattedMessage values={{ br: <br /> }} {...messages.companyDashboardLinksBillingInfoDescription} />
            </DashboardLink>
          </Grid>
          <Grid item md={4} xs={12}>
            <DashboardLink
              icon={IconInvoiceSvg}
              id="payments-dashboardlink"
              title={<FormattedMessage {...messages.companyDashboardLinksPaymentsOverviewTitle} />}
              to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}
            >
              <FormattedMessage
                values={{ br: <br /> }}
                {...messages.companyDashboardLinksPaymentsOverviewDescription}
              />
            </DashboardLink>
          </Grid>
        </Grid>
      </Grid>
      <CreateSiteModalDeprecated />
      <CreateSiteModal isNewCompany={isNewCompany} isFirstLogin={isFirstLogin} />
      <SiteAboutModal
        onAddSiteModal={isPaymentTypeSelectorFF ? openCreateSiteModal : openCreateSiteModalDeprecated}
        onOpenCreateCompanyModal={onOpenCreateCompanyModal}
      />
      <CreateCompanyModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  company: getCompanyData(state),
  isFirstLogin: authSelectors.isFirstLogin(state),
  paymentsTable: getDataGridSelector(state, PAYMENTS_MODULE, 'list'),
  sitesTable: getDataGridSelector(state, SITES_MODULE, 'recentSites'),
});

CompanyDashboard.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    sites: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  isFirstLogin: PropTypes.bool,
  onLoadPayments: PropTypes.func.isRequired,
  onLoadSites: PropTypes.func.isRequired,
  paymentsTable: dataGridProps.isRequired,
  sitesTable: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    didInvalid: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    order: PropTypes.string.isRequired,
    resultsTotal: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, {
  onLoadPayments: actionsPayments.getPaymentsList,
  onLoadSites: actionsSite.getSitesRecentList,
})(CompanyDashboard);
