import { pick } from 'lodash';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ApartmentApi from '../../api/apartmentApi';
import * as Api from '../../api/deviceApi';
import { FEATURES, SERVICE, VERIFICATION_STATUS } from '../../config/devices';
import { SERVICE as WS_SERVICE } from '../../config/websocket';
import editDeviceBasic from '../../containers/DeviceDetail/BasicDetail/saga';
import addDevice from '../../containers/DeviceModal/saga';
import { getDevicesTypesSettings, getServices } from '../../containers/DeviceModal/saga/helpers';
import { createExternalId } from '../../helpers/common';
import { selectors as apartmentSelectors } from '../apartments';
import * as apartmentActions from '../apartments/actions';
import apartmentTypes from '../apartments/types';
import { selectors as companySelectors } from '../company';
import {
  errorHelper,
  handleErrorLocation,
  prepareDeviceButtonConfiguration,
  responseValidateStatusOrThrow,
} from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { addSnackbarSuccess } from '../notifications/actions';
import { processError } from '../sagaHelpers';
import siteActions, { selectors as siteSelectors, types as siteTypes } from '../sites';
import { selectors as userSelectors } from '../user';
import { getDeleteDeviceSuccessActionName, getDeleteDeviceSuccessRedirectPath } from './helpers/deleteDeviceHelpers';
import { getFeatureByType, getFeaturesDevice, isActiveService, manageSwitch } from './helpers/helpers';
import actions, { devicesSelectors, selectors, types } from './index';
import messages from './messages';

export function* countRemoteConfigurationDevices({ context }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.countDeviceType, companyId, siteId, context);
    yield responseValidateStatusOrThrow(response);
    yield put({ context, payload: response.data, type: types.COUNT_DEVICE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.COUNT_DEVICE_FAILURE, error, context));
  }
}

export function* getDeviceDetailRequest({ companyId, deviceId, siteId }) {
  try {
    const response = yield call(Api.getDeviceDetail, companyId, siteId, deviceId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_DEVICE_DETAIL_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_DEVICE_DETAIL_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/devices'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* getCompanyAdminDevicesRequest(action) {
  const { companyId, filter, order, page, params, rowsPerPage, userId } = action;
  try {
    const response = yield call(Api.getCompanyAdminDevicesRequest, companyId, userId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_COMPANY_ADMIN_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_COMPANY_ADMIN_DEVICES_FAILURE, error));
  }
}

export function* getDevicesRequest(action) {
  const { companyId, context, filter, order, page, params, rowsPerPage, siteId, userId } = action;
  try {
    const { type } = filter;
    const currentUserId = yield select(userSelectors.getCurrentUserDetailId);
    const response = yield call(Api.getDevices, companyId, siteId, type, context, userId || currentUserId, params);
    yield responseValidateStatusOrThrow(response);
    yield put(siteActions.getCurrentSiteLicenseModel());
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_DEVICES_FAILURE, error));
  }
}

export function* getUserProfileDevicesRequest(action) {
  const { filter, order, page, params, rowsPerPage } = action;
  try {
    const response = yield call(Api.getUserProfileDevices, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_USER_PROFILE_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_USER_PROFILE_DEVICES_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* editDevice({ values }) {
  const copiedValues = values;
  try {
    if (values.newApartmentNumber !== undefined && values.newApartmentName !== undefined) {
      const floors = yield select(apartmentSelectors.getFloors);
      const companyId = yield select(companySelectors.getCompanyId);
      const siteId = yield select(siteSelectors.getSiteId);
      yield put(
        apartmentActions.addApartment(
          companyId,
          siteId,
          floors,
          {
            floor: values.newApartmentFloor,
            name: values.newApartmentName,
            number: values.newApartmentNumber,
          },
          false
        )
      );

      const addApartmentResponse = yield take([apartmentTypes.ADD_APARTMENT_SUCCESS]);
      copiedValues.apartmentId = addApartmentResponse.payload.id;
    }

    if (values.apartmentId === 0) {
      copiedValues.apartmentId = '';
      copiedValues.existApartment = false;
    }

    yield editDeviceBasic(copiedValues);
    yield put({ type: types.EDIT_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarUpdatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.EDIT_DEVICE_FAILURE, error));
  }
}

export function* getFirmwareVersion({ device }) {
  try {
    if (
      device?.id &&
      isActiveService(device, SERVICE.ACS) &&
      device?.services?.[SERVICE.ACS]?.lastSeen !== null &&
      device?.verificationStatus === VERIFICATION_STATUS.VERIFIED
    ) {
      const deviceId = device?.id;
      const siteId = device?.site?.id;
      const companyId = device?.site?.company?.id;
      const response = yield call(Api.getFirmwareVersion, companyId, siteId, deviceId);
      yield responseValidateStatusOrThrow(response);
      yield put({ payload: response.data, type: types.GET_FIRMWARE_VERSION_SUCCESS });
    }
  } catch (error) {
    yield put(errorHelper(types.GET_FIRMWARE_VERSION_FAILURE, error));
  }
}

export function* renewMobileVideoConfiguration() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    const response = yield call(Api.renewMobileVideoConfiguration, companyId, siteId, deviceId);
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.devicesSnackbarDeviceServiceSettingsRenewal));
    yield put({ data: response.data, type: types.MOBILE_VIDEO_RENEW_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.MOBILE_VIDEO_RENEW_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* updateDeviceService({ values }) {
  try {
    const { mobileVideoValue = null } = values;
    const { acsValue = null } = values;
    const { acValue = null } = values;
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    if (mobileVideoValue !== null) {
      const mobileVideo = yield select(selectors.getMobileVideoService(mobileVideoValue));
      const response = yield call(Api.updateService, companyId, siteId, deviceId, mobileVideo, SERVICE.MOBILE_VIDEO);
      yield responseValidateStatusOrThrow(response);
      yield put({ payload: response.data, type: types.UPDATE_DEVICE_SERVICE_SUCCESS });
    }
    if (acsValue !== null) {
      const acs = yield select(selectors.getACSService(acsValue));
      const response = yield call(Api.updateService, companyId, siteId, deviceId, acs, SERVICE.ACS);
      yield responseValidateStatusOrThrow(response);
      yield put({ payload: response.data, type: types.UPDATE_DEVICE_SERVICE_SUCCESS });
    }
    if (acValue !== null) {
      const response = yield call(Api.updateService, companyId, siteId, deviceId, acValue, SERVICE.ACCESS_CONTROL);
      yield responseValidateStatusOrThrow(response);
      yield put({ payload: response.data, type: types.UPDATE_DEVICE_SERVICE_SUCCESS });
    }
    yield put(addSnackbarSuccess(messages.devicesSnackbarUpdatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.UPDATE_DEVICE_SERVICE_FAILURE, error));
  }
}

export function* updateDevicePhonebook({ values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    const mobileVideo = yield select(selectors.getMobileVideoService(values));
    const response = yield call(Api.updateService, companyId, siteId, deviceId, mobileVideo, SERVICE.MOBILE_VIDEO);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.UPDATE_DEVICE_PHONEBOOK_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarPhonebookChangedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.UPDATE_DEVICE_PHONEBOOK_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* changeDeviceServiceStatus({ confirm, values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    const mobileVideo = yield select(selectors.getMobileVideoService(values));
    const response = yield call(Api.updateService, companyId, siteId, deviceId, mobileVideo, SERVICE.MOBILE_VIDEO);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.CHANGE_DEVICE_SERVICE_STATUS_SUCCESS,
    });
    if (confirm) {
      yield put(addSnackbarSuccess(messages.devicesSnackbarStatusChangedSuccessfully));
    }
  } catch (error) {
    yield put(errorHelper(types.CHANGE_DEVICE_SERVICE_STATUS_FAILURE, error));
  }
}

export function* updateFirmware({ values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    const response = yield call(Api.updateFirmwareVersion, companyId, siteId, deviceId, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.UPDATE_FIRMWARE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarFirmwareUpdateRequestSent));
  } catch (error) {
    yield put(errorHelper(types.UPDATE_FIRMWARE_FAILURE, error));
  }
}

export function* deleteDeviceRequest({ context, id }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const apartmentId = yield select(apartmentSelectors.getApartmentId);

    const response = yield call(Api.deleteDevice, companyId, siteId, id);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: getDeleteDeviceSuccessActionName(context) });
    yield put({
      payload: {
        to: getDeleteDeviceSuccessRedirectPath(context, { apartmentId, companyId, siteId }),
      },
      type: infraTypes.SET_REDIRECT_DATA,
    });
    yield put(addSnackbarSuccess(messages.devicesSnackbarDeletedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_DEVICE_FAILURE, error));
  }
}

export function* getWaitingDevicesList(action) {
  const { companyId, filter, page, params, rowsPerPage, siteId } = action;

  try {
    const response = yield call(Api.getWaitingDevices, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_WAITING_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_WAITING_DEVICES_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* approveDevice({ device, floors, values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const settings = yield getDevicesTypesSettings(companyId, device.type);
    const services = yield getServices(settings, values);
    let apartmentId = parseInt(values.apartmentId, 10) || 0;
    if (!values.existApartment) {
      const { floor } = values.newApartment;
      let floorData = {
        externalId: null,
        id: null,
        name: '',
      };
      const filteredFloor = floors.find((fl) => fl.name === floor);
      if (!filteredFloor) {
        if (floor) {
          yield put(apartmentActions.addFloor(companyId, siteId, { name: floor }));
          const addFloorResponse = yield take([apartmentTypes.ADD_FLOOR_SUCCESS]);
          floorData = addFloorResponse.payload;
        }
      } else {
        floorData = filteredFloor;
      }

      const responseApartment = yield call(ApartmentApi.addApartment, companyId, siteId, {
        externalId: createExternalId(),
        floorId: floorData?.id,
        name: values.newApartment.name,
        number: values.newApartment.number,
      });

      apartmentId = responseApartment.data.id;
    }
    const response = yield call(Api.approveDevice, companyId, siteId, device.id, {
      apartmentId: apartmentId !== 0 ? apartmentId : null,
      name: values.name,
      services,
      type: device.type,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.APPROVE_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarApprovedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.APPROVE_DEVICE_FAILURE, error));
  }
}

export function* rejectDevice({ device }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.rejectDevice, companyId, siteId, device);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REJECT_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarRejected));
  } catch (error) {
    yield put(errorHelper(types.REJECT_DEVICE_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* changeDeviceFromSocket({ context, data }) {
  if (context === WS_SERVICE.DEVICE_CHANGE) {
    const addDeviceData = yield select(selectors.getFinishedDataDeviceModal);
    if (
      addDeviceData?.id === data.id &&
      [VERIFICATION_STATUS.VERIFIED, VERIFICATION_STATUS.NOT_VERIFIED].includes(data.verificationStatus)
    ) {
      yield put({ payload: data, type: types.ADD_DEVICE_SUCCESS });
      yield put({ type: siteTypes.INVALIDATE_SITE_DASHBOARD });
    }

    const hasDefaultPasswordDevices = yield select(devicesSelectors.getDefaultPasswordDevices);
    if (data.hasDefaultPassword != null && hasDefaultPasswordDevices !== data.hasDefaultPassword) {
      const companyId = yield select(companySelectors.getCompanyId);
      const siteId = yield select(siteSelectors.getSiteId);
      yield getDefaultPasswordDevices({ companyId: companyId, params: { limit: 2 }, siteId: siteId });
    }

    const verifyDeviceData = yield select(selectors.getDeviceVerifyDeviceModal);
    if (
      verifyDeviceData?.id === data.id &&
      [VERIFICATION_STATUS.VERIFIED, VERIFICATION_STATUS.NOT_VERIFIED].includes(data.verificationStatus)
    ) {
      yield put({ payload: data, type: types.VERIFY_DEVICE_SUCCESS });
    }

    const detailDevice = yield select(selectors.getCurrentDeviceDetailSelector);
    if (detailDevice.data?.id === data.id) {
      yield put({ device: data, type: types.CHANGE_DEVICE_DETAIL_FROM_SOCKET });
    }
    yield put({ device: data, type: types.CHANGE_DEVICE_LIST_FROM_SOCKET });
  } else if (context === WS_SERVICE.REMOTE_CONFIGURATION_DEVICE_CHANGE) {
    const rcDevice = yield select(selectors.getDeviceRCModal);
    if (rcDevice?.services?.[SERVICE.REMOTE_CONFIGURATION]?.id === data.id) {
      yield put({ payload: data, type: types.RC_IFRAME_DEVICE_CHANGE });
    }
  } else if (context === WS_SERVICE.WAITING_DEVICE_CHANGE) {
    yield put({ type: types.GET_WAITING_DEVICES_RELOAD });
  }
}

export function* connectToRcWebsocket({ deviceId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.openRemoteConfiguration, companyId, siteId, deviceId, {
      action: 'KNOCK',
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RC_WEBSOCKET_KNOCK_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.RC_WEBSOCKET_KNOCK_FAILURE, error));
  }
}

export function* renewRemoteConfiguration({ deviceId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.renewRemoteConfiguration, companyId, siteId, deviceId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RC_RENEW_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarDeviceServiceSettingsRenewal));
  } catch (error) {
    yield put(errorHelper(types.RC_RENEW_FAILURE, error));
  }
}

export function* activateRemoteConfiguration({ active, deviceId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.activateRemoteConfiguration, companyId, siteId, deviceId, active);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RC_ACTIVATE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarStatusChangedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.RC_ACTIVATE_FAILURE, error));
  }
}

export function* createServiceConfiguration({ deviceId, service }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(siteSelectors.getSiteId);
  try {
    const response = yield call(Api.createServiceConfiguration, companyId, siteId, deviceId, service);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RC_CREATE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarStatusChangedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.RC_CREATE_FAILURE, error));
  }
}

export function* saveDeviceSwitchCodes({ values }) {
  try {
    const { data: device } = yield select(selectors.getCurrentDeviceDetailSelector);
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);

    const params = [companyId, siteId, device.id];
    const feature = device.features.find((item) => item.type === FEATURES.DTMF_SWITCH_LIST);

    if (feature?.id) {
      params.push(feature.id, {
        ...feature,
        switches: values.map((item) => manageSwitch(true, item)),
      });
    } else {
      params.push({
        switches: values.map((item) => manageSwitch(false, item)),
        type: FEATURES.DTMF_SWITCH_LIST,
      });
    }

    const response = yield call(feature?.id ? Api.updateDTMFSwitchList : Api.createFeatureDevice, ...params);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SAVE_DEVICE_SWITCH_CODES_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarUpdatedSuccessfully));
  } catch (error) {
    if (error.data?.code === 'IV26') {
      yield processError(types.SAVE_DEVICE_SWITCH_CODES_FAILURE, error, {
        data: {
          message: messages.dtmfListSaveFailure,
        },
      });
    } else {
      yield processError(types.SAVE_DEVICE_SWITCH_CODES_FAILURE, error);
    }
  }
}

export function* getCallSettingsDevices(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;

  try {
    const response = yield call(Api.getCallSettingsDevices, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_CALL_SETTINGS_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_CALL_SETTINGS_DEVICES_FAILURE, error));
  }
}

export function* getCallSettingsDevicesChips({ companyId, params, siteId }) {
  try {
    const response = yield call(Api.getCallSettingsDevices, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.GET_CALL_SETTINGS_DEVICES_CHIPS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_CALL_SETTINGS_DEVICES_CHIPS_FAILURE, error));
  }
}

export function* getLoadUsers({ companyId, siteId }) {
  try {
    const response = yield call(Api.getLoadUsersNames, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.LOAD_USERS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.LOAD_USERS_FAILURE, error));
  }
}

export function* updateDtmfCode({ code }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const deviceId = yield select(selectors.getCurrentDeviceIdSelector);
    const dtmfData = yield select(selectors.getDtmfCodeData);
    const response = yield call(
      Api.updateDtmfCode,
      companyId,
      siteId,
      deviceId,
      FEATURES.DTMF_CODE,
      dtmfData?.id || null,
      { ...dtmfData, dtmfCode: code }
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.UPDATE_DTMF_CODE_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarUpdatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.UPDATE_DTMF_CODE_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* saveDeviceContactListRequest({ serviceType, values }) {
  try {
    let method = Api.createFeatureDevice;
    const { data: device } = yield select(selectors.getCurrentDeviceDetailSelector);
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const params = [companyId, siteId, device.id];
    const feature = device.features.find((item) => item.type === FEATURES.CONTACT_LIST);
    if (feature && feature.id) {
      method = Api.updateFeatureDevice;
      params.push(FEATURES.CONTACT_LIST, feature.id, {
        ...feature,
        contacts: values.map((item) => ({
          ...item,
          action: item.id ? 'UPDATE_INTERCOM_CONTACT' : 'CREATE_INTERCOM_CONTACT',
          contactItems: item.contactItems.map((contact) => ({
            ...contact,
            action: contact.id ? `UPDATE_${contact.type}` : `CREATE_${contact.type}`,
          })),
          name: item.name,
        })),
      });
    } else {
      params.push({
        contacts: values.map((item) => ({
          ...item,
          action: 'CREATE_INTERCOM_CONTACT',
          contactItems: item.contactItems.map((contact) => ({
            ...contact,
            action: `CREATE_${contact.type}`,
          })),
        })),
        type: FEATURES.CONTACT_LIST,
      });
    }

    const response = yield call(method, ...params);
    yield responseValidateStatusOrThrow(response);
    if (serviceType === SERVICE.RINGING_GROUP) {
      const intercomButtonConfiguration = yield select(
        selectors.getDeviceCallSettingsList(FEATURES.BUTTON_CONFIGURATION)
      );
      const buttonConfigurationData = yield prepareDeviceButtonConfiguration(
        response.data,
        values,
        intercomButtonConfiguration,
        FEATURES.BUTTON_CONFIGURATION
      );

      yield saveDeviceButtonConfigurationRequest(device, buttonConfigurationData, FEATURES.BUTTON_CONFIGURATION);

      const intercomDisplayConfiguration = yield select(selectors.getDeviceCallSettingsList(FEATURES.DISPLAY));
      const displayConfigurationData = yield prepareDeviceButtonConfiguration(
        response.data,
        values,
        intercomDisplayConfiguration,
        FEATURES.DISPLAY
      );
      yield saveDeviceButtonConfigurationRequest(device, displayConfigurationData, FEATURES.DISPLAY);
    } else {
      yield put({ type: types.SAVE_DEVICE_CONTACT_LIST_SUCCESS });
      yield put(addSnackbarSuccess(messages.devicesSnackbarContactListUpdated));
    }
  } catch (error) {
    yield put(errorHelper(types.SAVE_DEVICE_CONTACT_LIST_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* saveCallSettingsMultipleRequest({ values }) {
  const errors = [];
  for (const item of values) {
    const { data, device, serviceType } = item;
    if (!device?.id) {
      break;
    }

    try {
      let method = Api.createFeatureDevice;
      const params = [device?.site?.company?.id, device?.site?.id, device.id];
      const features = getFeaturesDevice(device);
      const feature = getFeatureByType(FEATURES.CONTACT_LIST)(features);

      if (feature && feature.id) {
        method = Api.updateFeatureDevice;
        params.push(FEATURES.CONTACT_LIST, feature.id, {
          ...feature,
          contacts: data.map((row) => ({
            ...row,
            action: row.id ? 'UPDATE_INTERCOM_CONTACT' : 'CREATE_INTERCOM_CONTACT',
            contactItems: row.contactItems.map((contact) => ({
              ...contact,
              action: contact.id ? `UPDATE_${contact.type}` : `CREATE_${contact.type}`,
            })),
            name: row.name,
          })),
        });
      } else {
        params.push({
          contacts: data.map((row) => ({
            ...row,
            action: 'CREATE_INTERCOM_CONTACT',
            contactItems: row.contactItems?.map((contact) => ({
              ...contact,
              action: `CREATE_${contact.type}`,
            })),
          })),
          type: FEATURES.CONTACT_LIST,
        });
      }
      const response = yield call(method, ...params);
      yield responseValidateStatusOrThrow(response);
      if (serviceType === SERVICE.RINGING_GROUP) {
        const buttonConfiguration = getFeatureByType(FEATURES.BUTTON_CONFIGURATION)(features);
        const buttonConfigurationData = yield prepareDeviceButtonConfiguration(
          response.data,
          data,
          buttonConfiguration,
          FEATURES.BUTTON_CONFIGURATION
        );
        yield saveDeviceButtonConfigurationRequest(
          device,
          buttonConfigurationData,
          FEATURES.BUTTON_CONFIGURATION,
          false
        );
        const displayConfiguration = getFeatureByType(FEATURES.DISPLAY)(features);
        const displayConfigurationData = yield prepareDeviceButtonConfiguration(
          response.data,
          data,
          displayConfiguration,
          FEATURES.DISPLAY
        );
        yield saveDeviceButtonConfigurationRequest(device, displayConfigurationData, FEATURES.DISPLAY, false);
      }
    } catch (error) {
      errors.push(error.message);
    }
  }

  if (errors.length > 0) {
    yield put({ type: types.SAVE_CALL_SETTINGS_MULTIPLE_FAILURE });
  } else {
    yield put({ type: types.SAVE_CALL_SETTINGS_MULTIPLE_SUCCESS });
  }
}

// eslint-disable-next-line max-statements
export function* saveDeviceButtonConfigurationRequest(device, values, buttonType, flashMessage = true) {
  try {
    let method = Api.createFeatureDevice;
    const siteId = device?.site?.id;
    const companyId = device?.site?.company?.id;
    const params = [companyId, siteId, device.id];
    const feature = device.features.find((item) => item.type === buttonType);
    if (feature && feature.id) {
      method = Api.updateFeatureDevice;
      params.push(buttonType, feature.id, {
        ...feature,
        buttons: values.map((button) => ({
          ...button,
          action: button.id ? `UPDATE_${buttonType}_BUTTON` : `CREATE_${buttonType}_BUTTON`,
        })),
      });
    } else {
      params.push({
        buttons: values.map((button) => ({
          ...button,
          action: `CREATE_${buttonType}_BUTTON`,
        })),
        ...(buttonType === FEATURES.DISPLAY && { name: 'Display 1' }),
        type: buttonType,
      });
    }
    const response = yield call(method, ...params);
    yield responseValidateStatusOrThrow(response);
    if (flashMessage && buttonType === FEATURES.DISPLAY) {
      yield put({ type: types.SAVE_DEVICE_BUTTON_CONFIGURATION_SUCCESS });
      yield put(addSnackbarSuccess(messages.devicesSnackbarButtonConfigurationUpdated));
    }
  } catch (error) {
    if (flashMessage) {
      yield put(errorHelper(types.SAVE_DEVICE_BUTTON_CONFIGURATION_FAILURE, error));
    } else {
      throw error;
    }
  }
}

// eslint-disable-next-line max-statements
export function* verifyDeviceRequest({ values }) {
  try {
    const device = yield select(selectors.getDeviceVerifyDeviceModal);
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);

    const deviceValues = pick(device, ['displayLanguage', 'externalId', 'apartmentId']);
    const formValues = pick(values, ['name', 'serialNumber', 'securityCode', 'storeHash']);
    const response = yield call(Api.editDevice, companyId, siteId, device.id, {
      ...deviceValues,
      ...formValues,
    });
    if (response.status === 400 && response?.data?.code === 'IV59') {
      throw new Error('IV59: Validation error');
    } else {
      yield responseValidateStatusOrThrow(response);
    }
    yield put({ type: types.VERIFY_DEVICE_WAIT });
  } catch (error) {
    if (error.message !== 'IV59: Validation error') {
      yield put(errorHelper(types.VERIFY_DEVICE_FAILURE, 'An error occurred while verifying the device.'));
    } else yield put(errorHelper(types.VERIFY_DEVICE_FAILURE, error));
  }
}

export function* getNotVerifiedDevicesRequest(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    const response = yield call(Api.getNotVerifiedDevices, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_NOT_VERIFIED_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_NOT_VERIFIED_DEVICES_FAILURE, error));
  }
}

export function* getDefaultPasswordDevices({ companyId, params, siteId }) {
  try {
    const response = yield call(Api.getDefaultPasswordDevices, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: !!response.data.results?.length, type: types.GET_DEFAULT_PASSWORD_DEVICES_SUCCESS });
  } catch (error) {
    yield processError(types.GET_DEFAULT_PASSWORD_DEVICES_FAILURE, error);
  }
}

export function* addDeviceWait() {
  yield race({
    cancel: take(types.ADD_DEVICE_SUCCESS),
    task: delay(10000),
  });
  const isFinished = yield select(selectors.isFinishedAddDeviceModal);
  if (!isFinished) {
    yield put(actions.addDeviceStopWait());
    yield put({ type: siteTypes.INVALIDATE_SITE_DASHBOARD });
  }
}

export function* verifyDeviceWait() {
  yield race({
    cancel: take(types.VERIFY_DEVICE_SUCCESS),
    task: delay(10000),
  });
  const isFinished = yield select(selectors.isFinishedVerifyDeviceModal);
  if (!isFinished) {
    yield put(actions.verifyDeviceStopWait());
  }
}

export function* saveDeviceFeatureFaceTrackingRequest({ values }) {
  try {
    const { data: device } = yield select(selectors.getCurrentDeviceDetailSelector);
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const feature = device.features.find((item) => item.type === FEATURES.FACE_TRACKING);
    let response = yield call(
      Api.updateFeatureDevice,
      companyId,
      siteId,
      device.id,
      FEATURES.FACE_TRACKING,
      feature.id,
      {
        ...values,
        externalId: feature?.externalId || '',
        type: FEATURES.FACE_TRACKING,
      }
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response, type: types.UPDATE_DEVICE_SERVICE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.UPDATE_DEVICE_SERVICE_FAILURE, error));
  }
}

export default function* devicesSagas() {
  yield all([
    takeEvery(types.COUNT_DEVICE_REQUEST, countRemoteConfigurationDevices),
    takeEvery(types.ADD_DEVICE_REQUEST, addDevice),
    takeLatest(types.GET_DEVICES_REQUEST, getDevicesRequest),
    takeLatest(types.GET_COMPANY_ADMIN_DEVICES_REQUEST, getCompanyAdminDevicesRequest),
    takeLatest(types.GET_USER_PROFILE_DEVICES_REQUEST, getUserProfileDevicesRequest),
    takeEvery(types.GET_DEVICE_DETAIL_REQUEST, getDeviceDetailRequest),
    takeEvery(types.EDIT_DEVICE_REQUEST, editDevice),
    takeEvery(types.GET_FIRMWARE_VERSION_REQUEST, getFirmwareVersion),
    takeEvery(types.MOBILE_VIDEO_RENEW_REQUEST, renewMobileVideoConfiguration),
    takeEvery(types.UPDATE_DEVICE_SERVICE_REQUEST, updateDeviceService),
    takeEvery(types.CHANGE_DEVICE_SERVICE_STATUS_REQUEST, changeDeviceServiceStatus),
    takeEvery(types.UPDATE_DEVICE_PHONEBOOK_REQUEST, updateDevicePhonebook),
    takeEvery(types.UPDATE_FIRMWARE_REQUEST, updateFirmware),
    takeLatest(types.DELETE_DEVICE_REQUEST, deleteDeviceRequest),
    takeEvery(types.GET_WAITING_DEVICES_REQUEST, getWaitingDevicesList),
    takeEvery(types.APPROVE_DEVICE_REQUEST, approveDevice),
    takeEvery(types.REJECT_DEVICE_REQUEST, rejectDevice),
    takeEvery(types.CHANGE_DEVICE_FROM_SOCKET, changeDeviceFromSocket),
    takeEvery(types.RC_WEBSOCKET_KNOCK_REQUEST, connectToRcWebsocket),
    takeEvery(types.GET_CALL_SETTINGS_DEVICES_REQUEST, getCallSettingsDevices),
    takeEvery(types.GET_CALL_SETTINGS_DEVICES_CHIPS_REQUEST, getCallSettingsDevicesChips),
    takeEvery(types.SAVE_DEVICE_SWITCH_CODES_REQUEST, saveDeviceSwitchCodes),
    takeEvery(types.SAVE_DEVICE_CONTACT_LIST_REQUEST, saveDeviceContactListRequest),
    takeEvery(types.SAVE_CALL_SETTINGS_MULTIPLE_REQUEST, saveCallSettingsMultipleRequest),
    takeEvery(types.LOAD_USERS_REQUEST, getLoadUsers),
    takeEvery(types.UPDATE_DTMF_CODE_REQUEST, updateDtmfCode),
    takeEvery(types.RC_RENEW_REQUEST, renewRemoteConfiguration),
    takeEvery(types.RC_ACTIVATE_REQUEST, activateRemoteConfiguration),
    takeEvery(types.RC_CREATE_REQUEST, createServiceConfiguration),
    takeEvery(types.VERIFY_DEVICE_REQUEST, verifyDeviceRequest),
    takeLatest(types.GET_NOT_VERIFIED_DEVICES_REQUEST, getNotVerifiedDevicesRequest),
    takeLatest(types.GET_DEFAULT_PASSWORD_DEVICES_REQUEST, getDefaultPasswordDevices),
    takeEvery(types.ADD_DEVICE_WAIT, addDeviceWait),
    takeEvery(types.VERIFY_DEVICE_WAIT, verifyDeviceWait),
    takeEvery(types.SAVE_DEVICE_FEATURE_FACE_TRACKING_REQUEST, saveDeviceFeatureFaceTrackingRequest),
  ]);
}
