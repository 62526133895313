export default {
  APARTMENTS_IN_CALL_SETTINGS: 'APARTMENTS_IN_CALL_SETTINGS',
  ELEVATOR_INTEGRATION: 'ELEVATOR_INTEGRATION',
  FREE_MV_FOR_APARTMENT: 'FREE_MV_FOR_APARTMENT',
  GUEST_ACCESS: 'GUEST_ACCESS',
  PAYMENT_TYPE_SELECTOR: 'PAYMENT_TYPE_SELECTOR',
  QR_CODE: 'QR_CODE',
  SITE_BLE: 'SITE_BLE',
  USER_BLE_CREDENTIAL: 'USER_BLE_CREDENTIAL',
};
