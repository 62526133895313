import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSiteContext } from '../../../../context/useSiteContext';
import { useUrlParams } from '../../../../helpers/urlParams';
import useBreakpoints from '../../../../helpers/useBreakpoints';

export function useGetGlobalHooks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { upLg, upSm } = useBreakpoints();
  const { apartmentId, companyId, siteId } = useUrlParams();
  const isApartmentAdminEnabled = useSiteContext().isSiteFeatureEnabled('APARTMENT_ADMIN');

  return {
    breakpoints: {
      upLg,
      upSm,
    },
    dispatch,
    formatMessage,
    isApartmentAdminEnabled,
    navigate,
    urlParams: {
      apartmentId,
      companyId,
      siteId,
    },
  };
}
