import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import messages from '../messages';

interface ValidationSchemaProps {
  companyId: number;
  formatMessage: (message: MessageDescriptor) => string;
  phonebookData: IPhonebookFormData;
  siteId: number;
}

export interface IPhonebookFormData {
  externalId: string;
  id: number;
  members?: Array<number> | null;
  name: string;
}

export function getDefaultValues(phonebookData: IPhonebookFormData) {
  return {
    externalId: phonebookData?.externalId ?? null,
    id: phonebookData?.id ?? null,
    members: phonebookData?.members ?? [],
    name: phonebookData?.name ?? '',
  };
}

export function getValidationSchema({ companyId, formatMessage, phonebookData, siteId }: ValidationSchemaProps) {
  return yup.object().shape({
    externalId: yup.string().required(),
    id: yup.number().required(),
    members: yup.array().nullable(),
    name: yup
      .string()
      .required(formatMessage(messages.phonebookFormModalRequired))
      .test({
        message: formatMessage(messages.phonebookFormModalNameExist),
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0 || name === phonebookData.name) {
              return true;
            }
            await headSiteData({ companyId, siteId }, 'services/MOBILE_VIDEO/phonebooks', { name }, false).then(
              (response) => {
                state = response.status === 404;
              }
            );

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
  });
}
