import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SiteBasicInfo } from '../../../../apiRtk/be4feApi';
import { PATHS } from '../../../../config/consts';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useAppContext } from '../../../../pages/App/AppContextProvider';
import messages from '../../messages';
import { styles as switcherStyles } from '../../styles/commonSwitcher';
import { SiteOption } from './SiteOption';

export interface ISiteSwitcherProps {
  currentId: number;
  data: SiteBasicInfo[];
  isSideBar?: boolean;
  onAdd: () => void;
  onSelect: (id: number) => void;
}

export function SiteSwitcher({ currentId, data, isSideBar, onAdd, onSelect }: ISiteSwitcherProps) {
  const { feSiteRoles } = useAppContext();
  const companyId = useGetActualCompanyId();
  return (
    <Box
      sx={{
        ...(isSideBar ? switcherStyles.switcherSideBar : switcherStyles.switcherWrapper),
      }}
    >
      <Box display="flex" flexDirection="column" maxHeight={!isSideBar ? 400 : 'none'}>
        {feSiteRoles.isCompanyAdmin && (
          <Box
            sx={{
              px: 3,
              py: 1,
            }}
          >
            <Button
              component={Link}
              color="primary"
              id="sites-link"
              size="small"
              to={PATHS.COMPANY_SITES.replace(':companyId', companyId)}
            >
              <FormattedMessage {...messages.siteSwitcherViewAllSites} />
            </Button>
            <Box component="span" px={2}>
              <Badge badgeContent={data.length} color="primary">
                <span />
              </Badge>
            </Box>
          </Box>
        )}
        <MenuList
          disablePadding
          sx={{
            ...switcherStyles.listWrapper,
            ...(data.length > 7 && switcherStyles.scrollbar),
          }}
        >
          {data.map((item) => (
            <div key={item.id}>
              <SiteOption onSelect={onSelect} selected={currentId === item.id} siteData={item} />
            </div>
          ))}
        </MenuList>
      </Box>
      {feSiteRoles.isCompanyAdmin && (
        <>
          {data.length > 7 && !isSideBar && <Divider />}
          <Box sx={switcherStyles.addNewButton}>
            <Button
              color="primary"
              fullWidth
              id="add-site-button"
              onClick={() => onAdd()}
              startIcon={<AddIcon />}
              variant="outlined"
            >
              <FormattedMessage {...messages.siteSwitcherCreateSite} />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
