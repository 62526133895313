import { Theme } from '@mui/system';
import { menuListItem } from '../../../config/styles';

export const styles = {
  addNewButton: {
    display: 'inline-flex',
    p: 2.5,
  },
  listItem: (theme: Theme) => ({
    ...menuListItem,
    '&.Mui-selected': {
      '& span': {
        color: 'primary.main',
        fontWeight: 'bold',
      },
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
    '&.MuiMenuItem-root': {
      px: 2,
      py: 1.5,
    },
  }),
  listItemIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: 0,
      pr: 2.5,
    },
  },
  listItemName: {
    maxWidth: 196,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listWrapper: {
    width: '100%',
  },
  scrollbar: {
    overflowY: 'scroll',
  },
  switcherSideBar: {
    width: 'auto',
  },
  switcherWrapper: {
    width: 280,
  },
};
