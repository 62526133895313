export const moduleName = 'common';

export default {
  COLLAPSE_SIDEBAR: `${moduleName}/COLLAPSE_SIDEBAR`,
  COMPANY_SWITCHER_LOAD_DATA_FAILURE: `${moduleName}/COMPANY_SWITCHER_LOAD_DATA_FAILURE`,
  COMPANY_SWITCHER_LOAD_DATA_REQUEST: `${moduleName}/COMPANY_SWITCHER_LOAD_DATA_REQUEST`,
  COMPANY_SWITCHER_LOAD_DATA_SUCCESS: `${moduleName}/COMPANY_SWITCHER_LOAD_DATA_SUCCESS`,
  COMPANY_SWITCHER_SELECT_ACTION: `${moduleName}/COMPANY_SWITCHER_SELECT_ACTION`,
  GET_GTC_OPT_OUT_TEXT_FAILURE: `${moduleName}/GET_GTC_OPT_OUT_TEXT_FAILURE`,
  GET_GTC_OPT_OUT_TEXT_REQUEST: `${moduleName}/GET_GTC_OPT_OUT_TEXT_REQUEST`,
  GET_GTC_OPT_OUT_TEXT_SUCCESS: `${moduleName}/GET_GTC_OPT_OUT_TEXT_SUCCESS`,
  INVALIDATE_COMPANY_SWITCHER: `${moduleName}/INVALIDATE_COMPANY_SWITCHER`,
  INVALIDATE_SITE_SWITCHER: `${moduleName}/INVALIDATE_SITE_SWITCHER`,
  LOAD_GLOBAL_FEATURES_FAILURE: `${moduleName}/LOAD_GLOBAL_FEATURES_FAILURE`,
  LOAD_GLOBAL_FEATURES_REQUEST: `${moduleName}/LOAD_GLOBAL_FEATURES_REQUEST`,
  LOAD_GLOBAL_FEATURES_SUCCESS: `${moduleName}/LOAD_GLOBAL_FEATURES_SUCCESS`,
  LOAD_GTC_AGREEMENTS_FAILURE: `${moduleName}/LOAD_GTC_AGREEMENTS_FAILURE`,
  LOAD_GTC_AGREEMENTS_REQUEST: `${moduleName}/LOAD_GTC_AGREEMENTS_REQUEST`,
  LOAD_GTC_AGREEMENTS_SUCCESS: `${moduleName}/LOAD_GTC_AGREEMENTS_SUCCESS`,
  LOAD_GTC_DETAIL_FAILURE: `${moduleName}/LOAD_GTC_DETAIL_FAILURE`,
  LOAD_GTC_DETAIL_REQUEST: `${moduleName}/LOAD_GTC_DETAIL_REQUEST`,
  LOAD_GTC_DETAIL_SUCCESS: `${moduleName}/LOAD_GTC_DETAIL_SUCCESS`,
  LOAD_GTC_LIST_FAILURE: `${moduleName}/LOAD_GTC_LIST_FAILURE`,
  LOAD_GTC_LIST_REQUEST: `${moduleName}/LOAD_GTC_LIST_REQUEST`,
  LOAD_GTC_LIST_SUCCESS: `${moduleName}/LOAD_GTC_LIST_SUCCESS`,
  SEARCH: `${moduleName}/SEARCH`,
  SEND_SUPPORT_FORM_FAILURE: `${moduleName}/SEND_SUPPORT_FORM_FAILURE`,
  SEND_SUPPORT_FORM_REQUEST: `${moduleName}/SEND_SUPPORT_FORM_REQUEST`,
  SEND_SUPPORT_FORM_SUCCESS: `${moduleName}/SEND_SUPPORT_FORM_SUCCESS`,
  SET_SERVICE_ALERT_HEIGHT: `${moduleName}/SET_SERVICE_ALERT_HEIGHT`,
  SET_SERVICE_ALERT_SHOW: `${moduleName}/SET_SERVICE_ALERT_SHOW`,
  SIDEBAR_SET_CONTEXT: `${moduleName}/SIDEBAR_SET_CONTEXT`,
  SITE_VERSION_WARNING_SHOW: `${moduleName}/SITE_VERSION_WARNING_SHOW`,
};
