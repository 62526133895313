import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ActionModal } from '../../../components/_DesignSystemWrappers/Modal';
import OutlinedTextField from '../../../components/HookFormFields/TextField';
import { changeFilter } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import commonActions from '../../../modules/common';
import actions, { selectors } from '../../../modules/phonebooks';
import messages from '../messages';
import PhonebookMembersTable from './PhonebookMembersTable';
import { getDefaultValues, getValidationSchema, IPhonebookFormData } from './validationSchema';

function PhonebookFormModal() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();
  const phonebookMembersTable = useSelector(selectors.getPhonebookMembersTable);
  const [fulltext, setFulltext] = useState(phonebookMembersTable.filter.fulltext || '');
  const open = useSelector(selectors.isPhonebookModalOpen);
  const isEdit = useSelector(selectors.isPhonebookModalEdit);
  const phonebookData = useSelector(selectors.getPhonebookModalData);

  useEffect(() => {
    formMethods.reset(getDefaultValues(phonebookData));
  }, [open]);

  const formMethods = useForm<IPhonebookFormData>({
    defaultValues: getDefaultValues(phonebookData),
    mode: 'onChange',
    resolver: yupResolver(
      getValidationSchema({ companyId: companyId!, formatMessage, phonebookData, siteId: siteId! })
    ),
  });

  const onSubmit = () => {
    dispatch(actions.phonebookSave(companyId, siteId, formMethods.getValues(), isEdit));
  };

  const onCloseModal = () => {
    formMethods.reset();
    dispatch(actions.phonebookHideModal());
  };

  const onChangeFilter = ({ target }: any) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(
      (onLoad: () => void, params: object[]) => dispatch(commonActions.search(onLoad, params)),
      actions.loadMembers,
      {
        ...phonebookMembersTable,
        companyId,
        page: 0,
        siteId,
      }
    )(name, value);
  };

  return (
    <ActionModal
      icon={null}
      isPrimaryButtonDisabled={false}
      onClose={onCloseModal}
      open={open}
      muiDialogProps={{ maxWidth: 'sm' }}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.phonebookFormModalSubmit)}
      secondaryButtonText={formatMessage(messages.phonebookFormModalCancel)}
      title={
        isEdit
          ? formatMessage(messages.phonebookFormModalTitleEdit)
          : formatMessage(messages.phonebookFormModalTitleAdd)
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <OutlinedTextField
            fullWidth
            label={formatMessage(messages.phonebookFormModalLabelName)}
            name="name"
            required
            sx={{ marginTop: 2 }}
          />
          <Controller
            name="members"
            control={formMethods.control}
            defaultValue={[]}
            render={({ field }) => (
              <PhonebookMembersTable
                {...phonebookMembersTable}
                companyId={companyId!}
                field={{
                  name: 'members',
                  onChange: (e) => field.onChange(e.target.value),
                  value: field.value || [],
                }}
                fulltext={fulltext}
                onChangeFilter={onChangeFilter}
                onLoadMembers={(...args) => dispatch(actions.loadMembers(...args))}
                siteId={siteId!}
              />
            )}
          />
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default PhonebookFormModal;
