import { useContext } from 'react';
import { LicenceModelBillingType, SiteFeature } from '../apiRtk/be4feApi';
import { ISiteContextData, SiteContext } from './SiteContext';

export function useSiteContext() {
  const siteContext = useContext(SiteContext);

  if (siteContext === undefined) {
    return {
      hasSitePaymentModel: (_billingType: LicenceModelBillingType) => false,
      isSiteFeatureEnabled: (_feature: SiteFeature) => false,
    };
  }

  return {
    hasSitePaymentModel: (billingType: LicenceModelBillingType) => hasSitePaymentModel(siteContext, billingType),
    isSiteFeatureEnabled: (feature: SiteFeature) => isSiteFeatureEnabled(siteContext, feature),
  };
}

function isSiteFeatureEnabled(context: ISiteContextData, feature: SiteFeature) {
  return context.siteFeatures?.includes(feature) ?? false;
}

function hasSitePaymentModel(context: ISiteContextData, billingType: LicenceModelBillingType) {
  return context.billingType === billingType;
}
