import { useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { PATHS } from '../config/consts';
import { useGetActualCompanyId } from '../hooks/companyHooks';
import { Roles } from '../modules/commonTypes';
import { isApartmentAdminInSite } from '../modules/user/helpersTS';
import { replaceURLParams } from '../routes/helpers/pathParamsReplacer';
import { IUserContextData, UserContext } from './UserContext';

export function useUserContext() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const companyId = useGetActualCompanyId();

  if (userContext === undefined) {
    return {
      redirectUserToSite: () => navigate(PATHS.USER_PROFILE),
    };
  }

  return {
    redirectUserToSite: (siteId: number) => redirectUserToSite(userContext, navigate, companyId, siteId),
  };
}

function redirectUserToSite(
  userContext: IUserContextData,
  navigate: NavigateFunction,
  companyId: number,
  siteId: number
) {
  const company = userContext.companies.find((company) => company.id === companyId);
  if (company === undefined) {
    return navigate(PATHS.USER_PROFILE);
  }

  const site = company.sites.find((site) => site.id === siteId);
  if (site === undefined) {
    return navigate(PATHS.USER_PROFILE);
  }

  if (site.roles.includes(Roles.ADMIN)) {
    return navigate(
      replaceURLParams({
        params: { companyId: companyId.toString(), siteId: siteId.toString() },
        url: PATHS.SITE_DASHBOARD,
      })
    );
  }

  if (isApartmentAdminInSite(site)) {
    return navigate(PATHS.USERS_IN_APARTMENTS);
  }

  return navigate(PATHS.USER_PROFILE);
}
