import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../SelectSubtypeStep/messages';

export function LearnAboutSiteTypesText() {
  return (
    <Typography>
      <FormattedMessage
        {...messages.CreateSiteModalText2}
        values={{
          link: (chunks) => (
            <Link
              href="https://wiki.2n.com/faqmy2n/en/my2n-differences-between-site-models-and-examples-104866232.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              {chunks}
            </Link>
          ),
        }}
      />
    </Typography>
  );
}
