import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../components';
import useBreakpoints from '../../helpers/useBreakpoints';
import { IModalBase } from '../../modules/commonTypes';
import { selectors as devicesSelectors } from '../../modules/devices';
import deviceActions from '../../modules/devices';
import { selectors as sitesSelectors } from '../../modules/sites';
import FinishedStep from './FinishedStep';
import Loading from './Loading';
import messages from './messages';
import { OneStep } from './OneStep/OneStep';
import { getDefaultValues, getValidationSchema, IFormProps } from './validationSchema';

const VerifyDeviceModal = ({ onClose, open }: IModalBase) => {
  const [step, setStep] = useState(1);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { upSm } = useBreakpoints();
  const my2nId = useSelector(sitesSelectors.getMy2nId);
  const device = useSelector(devicesSelectors.getDeviceVerifyDeviceModal) as IFormProps;
  const isFinished = useSelector(devicesSelectors.isFinishedVerifyDeviceModal);
  const isWait = useSelector(devicesSelectors.isVerifyWaitModal);

  const formMethods = useForm({
    defaultValues: getDefaultValues(device),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  const handleVerify = () => {
    dispatch(deviceActions.verifyDevice(formMethods.getValues()));
  };

  useEffect(() => {
    if (isWait && step !== 2) {
      setStep(2);
    } else if (isFinished && step !== 3) {
      setStep(3);
    }
  }, [isWait, isFinished, step]);

  useEffect(() => {
    if (open) {
      setStep(1);
    }
  }, [open]);

  const getActions = () => {
    if (step === 1) {
      return (
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.formActionCancelBtn} />
          </Button>
          <Button
            color="primary"
            disabled={!formMethods.formState.isValid}
            onClick={formMethods.handleSubmit(handleVerify)}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messages.formActionNextBtn} />
          </Button>
        </>
      );
    }

    if (step === 3) {
      return (
        <Button color="primary" onClick={onClose} sx={{ mt: 2 }} variant="contained">
          <FormattedMessage {...messages.finishedStepFinished} />
        </Button>
      );
    }

    return null;
  };

  return (
    <FormProvider {...formMethods}>
      <form autoComplete="off" noValidate>
        <Modal
          actions={getActions()}
          fullWidth
          headerVariant="primary"
          maxWidth="sm"
          name="verify-device-modal"
          noCloseReasons={['escapeKeyDown', 'backdropClick']}
          onClose={onClose}
          open={open}
          isForm
          canClose
          title={<FormattedMessage {...messages.verifyDeviceModalTitle} />}
        >
          {step === 1 && <OneStep my2nId={my2nId} isMobileView={!upSm} />}
          {step === 2 && <Loading />}
          {step === 3 && <FinishedStep device={device} />}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default VerifyDeviceModal;
