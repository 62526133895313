import CheckIcon from '@mui/icons-material/Check';
import { MultistepDialog } from '@my2n/multistep-dialog';
import React, { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { PATHS } from '../../config/consts';
import { IModalBase } from '../../modules/commonTypes';
import { setRedirectData } from '../../modules/infrastructure/actions';
import { TYPE_DESCRIPTOR } from '../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../modules/notifications/actions';
import { clearSiteData } from '../../modules/sites/actions';
import { invalidateCurrentUser } from '../../modules/user/actionsTS';
import { CreateSiteStep } from './CreateSiteStep/CreateSiteStep';
import { ISiteModalValues, useGetGlobalHooks } from './hooks/UseGetGlobalHooks';
import messages from './messages';
import { SelectSiteTypeStep } from './SelectSiteTypeStep/SelectSiteTypeStep';
import { SelectSubtypeStep } from './SelectSubtypeStep/SelectSubtypeStep';

export interface ISiteModalProps extends IModalBase {
  isFirstLogin?: boolean;
  isNewCompany?: boolean;
}

export function SiteModal({ isFirstLogin, isNewCompany, onClose, open }: ISiteModalProps) {
  const { companyId, createSite, dispatch, downSm, formatMessage, formMethods } = useGetGlobalHooks();

  const submitButtonText = useMemo(
    () =>
      downSm ? formatMessage(messages.CreateSiteModalSubmitMobile) : formatMessage(messages.CreateSiteModalSubmit),
    [downSm]
  );

  async function handleSubmit(formData: ISiteModalValues): Promise<void> {
    if (companyId !== undefined && formData.siteType !== undefined) {
      try {
        const response = await createSite({
          companyId,
          createSiteRequest: {
            locale: formData.lang,
            name: formData.name,
            type: formData.siteType,
          },
        }).unwrap();

        dispatch(invalidateCurrentUser());
        dispatch(clearSiteData());
        dispatch(
          setRedirectData(
            PATHS.SITE_DASHBOARD.replace(':companyId', companyId.toString()).replace(':siteId', response.id.toString())
          )
        );
        dispatch(addSnackbarSuccess({ ...messages.CreateSiteModalSuccess }));
      } catch (error) {
        dispatch(addSnackbarError({ ...messages.CreateSiteModalError }, TYPE_DESCRIPTOR));
      }
    }
    onClose();
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <MultistepDialog
          testId="site-modal"
          title={formatMessage(messages.CreateSiteModalTitle)}
          backButtonLabel={formatMessage(messages.CreateSiteModalBack)}
          cancelButtonLabel={formatMessage(messages.CreateSiteModalCancel)}
          nextButtonLabel={formatMessage(messages.CreateSiteModalNext)}
          orderedSteps={[
            {
              children: <CreateSiteStep isFirstLogin={isFirstLogin} isNewCompany={isNewCompany} />,
              isNextDisabled:
                formMethods.formState.errors['name'] !== undefined ||
                !formMethods.formState.isDirty ||
                formMethods.getValues('name') === '',
            },
            {
              children: <SelectSiteTypeStep />,
              isNextDisabled: formMethods.getValues('siteCategory') === undefined,
            },
            {
              children: <SelectSubtypeStep />,
            },
          ]}
          submitButton={{
            icon: <CheckIcon />,
            isDisabled: !formMethods.formState.isValid,
            label: submitButtonText,
          }}
          onClose={onClose}
          open={open}
          onSubmit={formMethods.handleSubmit(handleSubmit)}
        />
      </form>
    </FormProvider>
  );
}
