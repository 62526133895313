import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/phonebooksApi';
import { selectors as companySelectors } from '../company';
import { errorHelper, handleErrorLocation, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { addSnackbarSuccess } from '../notifications/actions';
import { selectors as siteSelectors } from '../sites';
import actions from './index';
import messages from './messages';
import types from './types';

export function* loadPhonebooksList(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;

  try {
    const response = yield call(Api.listPhonebooks, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    yield put({
      filter,
      order,
      page,
      payload: {
        data: results.map((item) => ({
          deviceCount: (item.members || []).length,
          id: item.id,
          isDefault: item.isDefault || false,
          name: item.name,
        })),
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.LOAD_PHONEBOOKS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_PHONEBOOKS_LIST_FAILURE, error));
  }
}

export function* loadPhonebooksNamesList() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.listPhonebooksNames, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.LOAD_PHONEBOOKS_NAMES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_PHONEBOOKS_NAMES_FAILURE, error));
  }
}

export function* loadMembersList(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;

  try {
    const response = yield call(Api.listMembers, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    yield put({
      filter,
      order,
      page,
      payload: {
        data: results.map((item) => ({
          active: item.active,
          id: item.id,
          name: item.device.name,
          sipNumber: item.sipNumber,
          type: item.device.type,
        })),
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.LOAD_MEMBERS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_MEMBERS_LIST_FAILURE, error));
  }
}

export function* getDetailPhonebook({ companyId, phonebookId, siteId }) {
  try {
    const response = yield call(Api.detailPhonebook, companyId, siteId, phonebookId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.PHONEBOOK_DETAIL_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.PHONEBOOK_DETAIL_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/phonebook'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* savePhonebook(props) {
  const { companyId, isEdit, siteId, values } = props;

  try {
    let response;
    if (isEdit) {
      response = yield call(Api.updatePhonebook, companyId, siteId, values.id, values);
    } else {
      response = yield call(Api.createPhonebook, companyId, siteId, values);
    }
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.SAVE_PHONEBOOK_SUCCESS });
    yield put(addSnackbarSuccess(isEdit ? messages.phonebookSnackbarUpdated : messages.phonebookSnackbarAdded));
    yield put(actions.loadPhonebooks(companyId, siteId));
  } catch (error) {
    yield put({
      error: error.message ? error.message : error,
      isEdit,
      type: types.SAVE_PHONEBOOK_FAILURE,
    });
  }
}

export function* deletePhonebook({ phonebook }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.deletePhonebook, companyId, siteId, phonebook.id);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_PHONEBOOK_SUCCESS });
    yield put(actions.deletePhonebookHideModal());
    yield put(addSnackbarSuccess(messages.phonebookSnackbarDeleted));
    yield put(actions.loadPhonebooks(companyId, siteId));
  } catch (error) {
    yield put(errorHelper(types.DELETE_PHONEBOOK_FAILURE, error));
  }
}

export default function* phonebooksSagas() {
  yield all([
    takeLatest(types.LOAD_PHONEBOOKS_LIST_REQUEST, loadPhonebooksList),
    takeLatest(types.LOAD_PHONEBOOKS_NAMES_REQUEST, loadPhonebooksNamesList),
    takeLatest(types.LOAD_MEMBERS_LIST_REQUEST, loadMembersList),
    takeLatest(types.PHONEBOOK_DETAIL_REQUEST, getDetailPhonebook),
    takeLatest(types.SAVE_PHONEBOOK_REQUEST, savePhonebook),
    takeLatest(types.DELETE_PHONEBOOK_REQUEST, deletePhonebook),
  ]);
}
