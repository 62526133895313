import { SITE_TYPE } from '../config/sites';

export interface IEditSiteRequest {
  name: string;
  locale: string;
  apartmentDeviceLimit?: number;
  type?: Exclude<SITE_TYPE, SITE_TYPE.UNKNOWN>;
}

export enum SiteCategory {
  DEPRECATED = 'DEPRECATED',
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}
